<template>
  <div>
    <CCard>
      <CCardBody>
        <div class="row justify-content-between">
          <div class="col-10">
            <div class="row mb-3">
              <label class="m-1 ml-3" for="">Search : </label>
              <input
                type="text"
                v-model="params.keyword"
                style="max-width: 200px"
                class="form-control form-control-sm mx-2"
                placeholder="Ketik disini"
              />
              <button @click="search()" class="btn btn-sm btn-success">
                Cari
              </button>
            </div>
          </div>
          <div class="col-2">
            <div class="row">
              <div class="col-12">
                <div class="input-group input-group-sm mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01"
                      >Per Halaman</label
                    >
                  </div>
                  <select
                    class="custom-select"
                    style="max-width: 100px"
                    id="inputGroupSelect01"
                    v-model="params.row"
                    @change="getData()"
                  >
                    <!-- <option selected>Pilih...</option> -->
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="2000">2000</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-2">

          <select
            style="max-width: 200px"
            class="form-control form-control-sm mx-2 col"
            placeholder="Ketik disini"
            v-model="params.year"
            @change="getData()"
          >
            <option value="" >Semua Tahun</option>
            <option value="2024">2024</option>
            <option value="2023">2023</option>
          </select>

          <select style="max-width: 400px"
            class="form-control form-control-sm mx-2 col"
            placeholder="Ketik disini"
            v-model="params.document_type"
            @change="getData()"
          >
            <option value="">Semua Jenis Dokumen</option>
            <option
                v-for="document_type in document_types"
                :value="document_type.id"
                :key="document_type.id"
              >
                {{ document_type.name }}
              </option>
          </select>
            
        </div>

        <div class="row" v-if="isSearching">
          <div class="col">
            <h3>
              <span class="badge bg-primary text-light text-bor my-auto">
                {{searchOn}}&nbsp;&nbsp;
                <span @click="searchOff" class="badge bg-light text-dark text-center" style="cursor: pointer">X</span>
              </span>
            </h3>
          </div>
        </div>
        <CDataTable
          class="table-striped"
          :items="computedItems"
          :fields="fields"
          sorter
        >
        <template #select="{ item }">
          <td class="py-2">
            <input 
                type="checkbox"
                @change="check(item)"
                v-model="item.select"
              />
          </td>
        </template>
          <template #draf_sk="{ item }">
            <td class="py-2">
              <CButton
                @click="download(item)"
                class="mr-2"
                color="success"
                square
                size="sm"
              >
                Download
              </CButton>
            </td>
          </template>
        </CDataTable>
        <pagination
          v-if="total !== items.length"
          v-model="page"
          :records="total"
          :per-page="100"
          @paginate="pagination"
        />
      </CCardBody>
    </CCard>
    <CModal
      size="lg"
      :title="'Filter Status'"
      centered
      :color="'primary'"
      :show.sync="createModal"
    >
      <div class="row">
        <div class="col-12">
          <label for="">Status</label>
        </div>
      </div>
      <template slot="footer">
        <div>
          <!-- <button @click="submitStatus" class="btn btn-primary">
            Submit
          </button> -->
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import * as data from "../../model/document_finished";

export default {
  data() {
    return {
      file: null,
      createModal: false,
      createModalImport: false,
      fields: [],
      isUpdate: false,
      items: [],
      selectedItems: [],
      isSelectedAll: false,
      selectedAction: 0,
      page: 1,
      total: 0,
      to: 0,
      user: JSON.parse(localStorage.getItem("user")),
      params: {
        sorttype: "desc",
        sortby: "id",
        row: 100,
        page: 1,
        keyword: "",
        year: "",
        document_type: ""
      },
      isSearching: false,
      searchOn: '',
      document_types: []
    };
  },
  methods: {
    download(item) {
      console.log(item.draft_sk, "wkwkw")
      window.open(item.draft_sk, "_blank");
    },
    getDataDocumentType() {
       const params = {
            sorttype: "desc",
            sortby: "id",
            row: 100,
            page: 1,
            keyword: "",
        };
        var loading = this.$loading.show();
        this.$store
            .dispatch("document_type/get", params)
            .then((resp) => {
            this.document_types = resp.data.data;
            // resp.data.data.forEach(element => {
            //   this.document_types.push([element.name, element.name])
            // });
            loading.hide();
        })
        .catch((e) => {
            this.$toast.error("gagal mengambil data  \n", e);
            loading.hide();
        });
    },
    addModalFilter() {
      this.createModal = true;
    },
    search() {
      if (this.params.keyword != "") {
        this.isSearching = true;
        this.getData();
        this.searchOn = this.params.keyword;
      } else {
        this.$toast.error("Inputan tidak boleh kosong !!");
      }
    },
    searchOff(){
      this.isSearching = false;
      this.params.keyword = '';
      this.getData();
    },
    getData() {
      this.items = [];
      var loading = this.$loading.show();
      this.$store
        .dispatch("document/getDocumentFinished", this.params)
        .then((resp) => {
          console.log(resp.data, "testing");
          this.items = resp.data.data;
          this.total = resp.data.total;
          this.to = resp.data.to;
          loading.hide();
        })
        .catch((e) => {
          // this.$toast.error("gagal mengambil data  \n", e);
          loading.hide();
        });
    },
    pagination(page) {
      this.page = page;
      this.params.page = page;
      this.getData();
    },
  },
  computed: {
    computedItems() {  
      console.log(this.items)
      return this.items.map((item, index) => {

        const stripHtmlTags = (html) => {
        const div = document.createElement("div");
        div.innerHTML = html;
        return div.textContent || div.innerText || "";
      };

        const plainTextTitle = stripHtmlTags(item.tittle);
        const truncatedTitle = plainTextTitle.length <= 55
          ? plainTextTitle
          : plainTextTitle.slice(0, 55) + ' ...';

        return {
          index: (this.to - this.items.length) + index+1 + '.',
          ...item,
          document_type: item.document_type ? item.document_type.name : '-', 
          created_at: this.$moment(item.created_at).format("Do MMMM YYYY HH:mm"),
          year: this.$moment(item.updated_at).format("YYYY"),
          tittle: truncatedTitle
        };
      });
    },
  },
  mounted() {
    this.getData();
    this.getDataDocumentType();
  },
  created() {
    this.fields = data.fieldsAdmin
  },
};
</script>
