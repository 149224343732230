<template>
  <!-- HTML template for the component -->
  <div>
    <table class="table-naskah" width="100%">
      <tbody>
        <tr>
            <td style="vertical-align: baseline;width: 136px"></td>
            <td style="vertical-align: baseline;width: 18.8px"></td>
            <td colspan="2" style="text-align: justify;text-justify: inter-word;vertical-align: baseline;width: 37.7px">Agar setup orang mengetahuinya, memerintahkan pengundangan Peraturan Bupati ini dengan penempatannya dalam Berita Daerah Kabupaten Bandung.</td>
        </tr>
      </tbody>
    </table>
    <div class="row mt-3" style="margin-top: 3rem !important;">
      <div class="col-5">&nbsp;</div>
      <div class="col-7">
        <p style="margin-left: 60px">Ditetapkan di Soreang</p>
        <p v-if="document.document.penomoran" style="margin-left:60px">Pada Tanggal {{ formatDate(document.document.determination_date) }}</p>
        <p v-else style="margin-left:60px">Pada Tanggal </p>
        <p
          style="padding: 0px 50px 0px 0px; line-height: 3; text-align: center"
        >
          BUPATI BANDUNG
        </p>
        <div style="text-align: center; 
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 40%;"><qr-code  size="100" :text="document.document.qrcode"></qr-code></div>
        <p
          style="padding: 0px 20px 0px 0px; line-height: 3; text-align: center"
        >
          MOCHAMMAD DADANG SUPRIATNA
        </p>
      </div>
    </div>
    <div class="row mt-3" style="margin-top: 3rem !important;">
      <div class="col-6">
        <p>Diundang di Soreang</p>
        <p v-if="document.document.penomoran">Pada Tanggal {{ formatDate(document.document.determination_date) }}</p>
        <p v-else>Pada Tanggal </p>
        <div
          style="padding: 20px 50px 20px 0px;text-align: center"
        >
          <p style="margin-bottom: 0 !important">SEKRETARIS DAERAH,</p>
          <p style="margin-bottom: 0 !important">KABUPATEN BANDUNG</p>
        </div>
        <div style="text-align: center; 
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 40%;"><qr-code  size="100" :text="document.document.qrcode"></qr-code></div>
        <p
          style="padding: 0px 20px 0px 0px; line-height: 3; text-align: center"
        >
          CAKRA AMIYANA
        </p>
      </div>
      <div class="col-6">&nbsp;</div>
    </div>
    <p>BERITA DAERAH KABUPATEN BANDUNG TAHUN 2024 NOMOR {{  this.document.document_number }}</p>
  </div> 
</template>

<script>
export default {
  // Component's data properties
  data() {
    return {
      document: {},
      base_url: ""
    };
  },
  // Component's methods
  methods: {
    formatDate(date) {
        return this.$moment(date).format("Do MMMM YYYY");
    },
  },
  mounted() {
      // this.$emit('can-continue', {value: true});
      this.document = this.$store.state.document;
      console.log(this.base_url, "document");
  },
};
</script>
