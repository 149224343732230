<template>
  <!-- HTML template for the component -->
  <div>
    <div class="row mt-3">
      <div class="col-5">&nbsp;</div>
      <div class="col-7">
        <p style="margin-left: 60px">Ditetapkan di Soreang</p>
        <p v-if="document.document.penomoran" style="margin-left: 60px">
          Pada Tanggal {{ formatDate(document.document.determination_date) }}
        </p>
        <p v-else style="margin-left: 60px">Pada Tanggal</p>
        <p
          style="padding: 0px 50px 0px 0px; line-height: 3; text-align: center"
        >
          BUPATI BANDUNG
        </p>
        <div
          style="
            text-align: center;
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 40%;
          "
        >
          <qr-code size="100" :text="document.document.qrcode"></qr-code>
        </div>
        <p
          style="padding: 0px 20px 0px 0px; line-height: 3; text-align: center"
        >
          {{
            this.$moment(document.document.determination_date).isBetween(
              this.$moment("2024-9-26"),
              this.$moment("2024-11-25"),
              "days"
            )
              ? "DIKKY ACHMAD SIDIK"
              : "MOCHAMMAD DADANG SUPRIATNA"
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // Component's data properties
  data() {
    return {
      document: {},
      base_url: "",
    };
  },
  // Component's methods
  methods: {
    formatDate(date) {
      return this.$moment(date).format("Do MMMM YYYY");
    },
  },
  mounted() {
    // this.$emit('can-continue', {value: true});
    this.document = this.$store.state.document;
    console.log(this.base_url, "document");
  },
};
</script>
