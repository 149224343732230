<template>
    <section class="section"> 
        <div class="columns">
            <div class="column is-8 is-offset-2">
                <horizontal-stepper :steps="document_type == 'INSTRUKSI BUPATI' ? instructionSteps : (document_type == 'PERATURAN BUPATI') ? peraturanSteps : demoSteps" @completed-step="completeStep"
                                    @active-step="isStepActive" @stepper-finished="alert" 
                >                     
                </horizontal-stepper>
            </div>
        </div>
    </section>
</template>

<script>
    import HorizontalStepper from 'vue-stepper';
 
    // This components will have the content for each stepper step.
    import FormPengajuan from './DocumentDetail/FormPengajuan.vue';
    import FormMenimbang from './DocumentDetail/FormMenimbang.vue';
    import FormMengingat from './DocumentDetail/FormMengingat.vue';
    import FormMemperhatikan from './DocumentDetail/FormMemperhatikan.vue';
    import FormKeputusan from './DocumentDetail/FormKeputusan.vue';
    import FormLampiran from './DocumentDetail/FormLampiran.vue';
    import FormPreview from './DocumentDetail/FormPreview.vue';
 
    export default {
        components: {
            HorizontalStepper,
        },
        data(){
            return {
                document: {},
                document_type: "",
                demoSteps: [
                    {
                        icon: 'description',
                        name: 'satu',
                        subtitle: '1. Pengajuan',
                        component: FormPengajuan,
                        completed: false
                    },
                    {
                        icon: 'description',
                        name: 'dua',
                        subtitle: '2. Menimbang',
                        component: FormMenimbang,
                        completed: false
                    },
                    {
                        icon: 'description',
                        name: 'tiga',
                        subtitle: '3. Mengingat',
                        component: FormMengingat,
                        completed: false
                    },
                    {
                        icon: 'description',
                        name: 'empat',
                        subtitle: '4. Memperhatikan',
                        component: FormMemperhatikan,
                        completed: false
                    },
                    {
                        icon: 'description',
                        name: 'lima',
                        subtitle: '5. Diktum Keputusan',
                        component: FormKeputusan,
                        completed: false
                    },
                    {
                        icon: 'description',
                        name: 'enam',
                        subtitle: '6. Lampiran',
                        component: FormLampiran,
                        completed: false
                    },
                    {
                        icon: 'description',
                        name: 'tujuh',
                        subtitle: '7. Preview',
                        component: FormPreview,
                        completed: false
                    }
                ],
                instructionSteps: [
                    {
                        icon: 'description',
                        name: 'satu',
                        subtitle: '1. Pengajuan',
                        component: FormPengajuan,
                        completed: false
                    },
                    {
                        icon: 'description',
                        name: 'dua',
                        subtitle: '2. Menimbang',
                        component: FormMenimbang,
                        completed: false
                    },
                    {
                        icon: 'description',
                        name: 'tiga',
                        subtitle: '3. Instruksi Kepada',
                        component: FormMengingat,
                        completed: false
                    },
                    {
                        icon: 'description',
                        name: 'lima',
                        subtitle: '4. Isi Instruksi',
                        component: FormKeputusan,
                        completed: false
                    },
                    {
                        icon: 'description',
                        name: 'enam',
                        subtitle: '5. Lampiran',
                        component: FormLampiran,
                        completed: false
                    },
                    {
                        icon: 'description',
                        name: 'tujuh',
                        subtitle: '6. Preview',
                        component: FormPreview,
                        completed: false
                    }
                ],
                peraturanSteps: [
                {
                        icon: 'description',
                        name: 'satu',
                        subtitle: '1. Pengajuan',
                        component: FormPengajuan,
                        completed: false
                    },
                    {
                        icon: 'description',
                        name: 'dua',
                        subtitle: '2. Menimbang',
                        component: FormMenimbang,
                        completed: false
                    },
                    {
                        icon: 'description',
                        name: 'tiga',
                        subtitle: '3. Mengingat',
                        component: FormMengingat,
                        completed: false
                    },
                    {
                        icon: 'description',
                        name: 'empat',
                        subtitle: '4. Memperhatikan',
                        component: FormMemperhatikan,
                        completed: false
                    },
                    {
                        icon: 'description',
                        name: 'lima',
                        subtitle: '5. Keputusan',
                        component: FormKeputusan,
                        completed: false
                    },
                    {
                        icon: 'description',
                        name: 'enam',
                        subtitle: '6. Lampiran',
                        component: FormLampiran,
                        completed: false
                    },
                    {
                        icon: 'description',
                        name: 'tujuh',
                        subtitle: '7. Preview',
                        component: FormPreview,
                        completed: false
                    }
                ]
            }
        },
        methods: {
            leaving() {
                alert("Leaving...");
            },
            // Executed when @completed-step event is triggered
            completeStep(payload) {
                this.demoSteps.forEach((step) => {
                    if (step.name === payload.name) {
                        step.completed = true;
                    }
                })
            },
            // Executed when @active-step event is triggered
            isStepActive(payload) {
                // Handle step activation
                console.log(payload);
                this.demoSteps.forEach((step) => {
                    if (step.name === payload.name) {
                        if(step.completed === true) {
                            step.completed = false;
                        }
                    }
                })
            },
            // Executed when @stepper-finished event is triggered
            alert(payload) {
                this.document = this.$store.state.document;
                this.$swal.fire({
                    icon: 'warning',
                    html: 'Apakah yakin data yang anda sudah benar?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        this.submit();
                    } 
                });
            },
            submit() {

                // convert json to string
                if (this.document.document_decisions.length > 0) {
                    this.document.document_decisions.forEach(element => {
                        element.extend_form = JSON.stringify(element.extend_form);
                    });
                }

                let doc = {
                    id: this.document.document.id,
                    document_type: this.document.document.document_type,
                    total_attachment: this.document.document.total_attachment,
                    tittle: this.document.document.title,
                    signer: this.document.document.signer,
                    date: this.document.document.date,
                    determination_date: this.document.document.determination_date,
                    proposer: this.document.document.proposer,
                    unit: this.document.document.unit,
                    document_number: this.document.document.document_number,
                    document_type_id: this.document.document.document_type_id.value,
                    document_activity_id: this.document.document.document_activity_id.value,
                    document_attachments: this.document.document_attachments,
                    document_considers: this.document.document_considers,
                    document_decisions: this.document.document_decisions,
                    document_notices: this.document.document_notices,
                    document_remembers: this.document.document_remembers,
                    document_supports: this.document.document_supports,
                    document_copies: this.document.document_copies,
                    draft_sk: this.document.document.draft_sk,
                    qrcode: this.document.document.qrcode
                }

                if (doc.id) {

                    if (this.document.document.penomoran && doc.document_number) {
                        if (this.document.document.penomoran == 'eoffice') {
                            doc.status = "UPLOAD EOFFICE";
                            doc.document_statuses = {
                                status: "UPLOAD EOFFICE",
                                remark: "DOKUMEN SUDAH DI UPLOAD KE E-OFFICE"
                            }
                        } else {
                            doc.status = "PENOMORAN BELUM MERGE";
                            doc.document_statuses = {
                                status: "PENOMORAN BELUM MERGE",
                                remark: "DOKUMEN SUDAH DIBUAT NOMOR, BELUM MERGE"
                            }
                        }
                    } else {
                        doc.status = "DIEDIT";
                        doc.document_statuses = {
                            status: "DIEDIT",
                            remark: "EPRODUK TELAH DI EDIT"
                        }
                    }

                    let path = "document/update";
                    var loading = this.$loading.show();
                    this.$store
                        .dispatch(path, {
                            id: doc.id,
                            data: doc,
                        })
                        .then(() => {
                            this.$swal.fire({
                                icon: 'success',
                                text: 'Berhasil mengubah data!',
                            });
                            loading.hide();
                            this.$store.commit('document/setDocument', {});
                            this.$store.commit('document/setDocumentRemembers', []);
                            this.$store.commit('document/setDocumentNotices', []);
                            this.$store.commit('document/setDocumentDecisions', []);
                            this.$store.commit('document/setDocumentAttachments', []);
                            this.$store.commit('document/setDocumentSupports', []);
                            this.$store.commit('document/setDocumentCopies', []);
                            this.$router.push({ path: 'Document' });
                        })
                        .catch((e) => {
                            console.log(e)
                            loading.hide();
                        });
                } else {
                    doc.status = "PENGAJUAN";
                    doc.document_statuses = {
                        status: "PENGAJUAN",
                        remark: "EPRODUK BERHASIL DIAJUKAN"
                    }

                    var loading = this.$loading.show();

                    let path = "document/add";
                    this.$store
                        .dispatch(path, doc)
                        .then(() => {
                            this.$swal.fire({
                                icon: 'success',
                                text: 'Berhasil menambahkan data!',
                            });
                            loading.hide();
                            this.$store.commit('document/setDocument', {});
                            this.$store.commit('document/setDocumentRemembers', []);
                            this.$store.commit('document/setDocumentNotices', []);
                            this.$store.commit('document/setDocumentDecisions', []);
                            this.$store.commit('document/setDocumentAttachments', []);
                            this.$store.commit('document/setDocumentSupports', []);
                            this.$store.commit('document/setDocumentCopies', []);
                            this.$router.push({ path: 'Document' });
                        })
                        .catch((e) => {
                            loading.hide();
                        });
                }

                
            },
        },
        mounted() {
            this.document_type = this.$store.state.document.document_type;
            // jika document type nya ga ada arahkan ke list dokumen
            if (!this.document_type) {
                this.$router.push({ path: 'Document' });
            }
        },
        ready() {
            Vue.util.on(document.getElementsByTagName('body')[0], 'DOMFocusOut',
                this.leaving,
                false);
        },
    }
</script>

<style>
    .stepper-box .top .steps-wrapper .step .step-title h5 {
        font-size: 12px !important;
        font-weight: bold !important;
    }

    .stepper-box .top .steps-wrapper .step .circle i {
        background-color: #024531 !important;
    }
</style>