<template>
    <div class="container">
        <div >
        <!-- <div> -->
            <!-- {{ document }} -->
            <div id="pdf-content" style="background-color:white" class="custom-font">
                <!-- <img src="@/assets/logo/sekdalogo.png" style="max-width: 100%; height: auto;" /> -->
                <div class="content" style="margin-left: 75.59px; margin-right: 94.49px;">

                <!-- Jika Instruksi Bupati -->
                <HeaderInstruksiBupati v-if="document.document.document_type_id.label == 'INSTRUKSI BUPATI'"></HeaderInstruksiBupati>
                <!-- End Jika Instruksi Bupati -->

                <!-- Jika Bupati -->
                <HeaderBupati v-else-if="document.document.document_type_id.label == 'KEPUTUSAN BUPATI'"></HeaderBupati>
                <!-- End Jika Bupati -->

                <!-- Jika Bupati -->
                <HeaderPeraturanBupati v-else-if="document.document.document_type_id.label == 'PERATURAN BUPATI'"></HeaderPeraturanBupati>
                <!-- End Jika Bupati -->
                
                <!-- Jika Sekda Versi BIASA  -->
                <HeaderSekda v-else-if="document.document.document_type_id.label == 'KEPUTUSAN SEKDA'"></HeaderSekda>
                <!-- End Sekda Versi BIASA -->

                <!-- Jika Sekda Versi PENGGUNA ANGGARAN  -->
                <HeaderSekdaAnggaran v-else-if="document.document.document_type_id.label == 'KEPUTUSAN SEKDA PENGGUNA ANGGARAN'"></HeaderSekdaAnggaran>
                <!-- End Sekda Versi PENGGUNA ANGGARAN -->

                <!-- Jika Sekda Versi PENGGUNA BARANG  -->
                <HeaderSekdaBarang v-else-if="document.document.document_type_id.label == 'KEPUTUSAN SEKDA PENGGUNA BARANG'"></HeaderSekdaBarang>
                <!-- End Sekda Versi PENGGUNA ANGGARAN -->

                <template v-if="document.document.document_type_id.label == 'INSTRUKSI BUPATI'">
                    <table style="width: 100%;">
                        <tr>
                            <td style="text-align: center;" v-if="document.document.penomoran">NOMOR {{document.document ? document.document.document_number : '' }}</td>
                            <td style="text-align: center;" v-else>NOMOR - </td>
                        </tr>
                    </table>
                    <table style="margin:auto">
                        <tr v-if="document.document.total_attachment">
                            <td>LAMPIRAN</td>
                            <td style="padding-left: 37.7px; padding-right: 18.8px">:</td> 
                            <!-- <td v-if="document.document_attachments">{{ document.document_attachments.length }} ({{ pembilang(document.document_attachments.length, false) }})</td> -->
                            <td v-if="document.document.total_attachment">{{ document.document.total_attachment }} ({{ pembilang(document.document.total_attachment, false) }})</td>
                        </tr>
                    </table>
                </template>
                <template v-else-if="document.document.document_type_id.label == 'PERATURAN BUPATI'">
                    <table style="width: 100%;">
                        <tr>
                            <td style="text-align: center;" v-if="document.document.penomoran">NOMOR {{document.document ? document.document.document_number : '' }}</td>
                            <td style="text-align: center;" v-else>NOMOR - </td>
                        </tr>
                    </table>
                </template>
                <template v-else>
                    <table style="margin-left: 143.6px;">
                    <tr>
                        <td>NOMOR</td>
                        <td style="padding-left: 37.7px; padding-right: 18.8px">:</td>
                        <td v-if="document.document.penomoran">{{document.document ? document.document.document_number : '' }}</td>
                    </tr>
                    <tr v-if="document.document.total_attachment">
                        <td>LAMPIRAN</td>
                        <td style="padding-left: 37.7px; padding-right: 18.8px">:</td> 
                        <!-- <td v-if="document.document_attachments">{{ document.document_attachments.length }} ({{ pembilang(document.document_attachments.length, false) }})</td> -->
                        <td v-if="document.document.total_attachment">{{ document.document.total_attachment }} ({{ pembilang(document.document.total_attachment, false) }})</td>
                    </tr>
                </table>
                </template>
                
                <p style="text-align:center; margin:0; line-height: 3">TENTANG</p> 
                <p style="text-align:center; line-height: 1.6; margin-left: 22.6px;margin-right: 22.6px;" v-html="document.document.title">
                </p>
                
                <!-- Jika Bupati -->
                <p style="text-align:center; margin:0; line-height: 3" v-if="document.document.document_type_id.label == 'KEPUTUSAN BUPATI'">BUPATI BANDUNG,</p>

                <p style="text-align:center; margin:0; line-height: 3" v-if="document.document.document_type_id.label == 'PERATURAN BUPATI'">DENGAN RAHMAT TUHAN YANG MAHA ESA</p>
                <p style="text-align:center; margin:0; line-height: 2" v-if="document.document.document_type_id.label == 'PERATURAN BUPATI'">BUPATI BANDUNG,</p>

                <p style="text-align:center; margin-bottom:0; line-height: 3; padding-bottom: 15px;" v-else-if="document.document.document_type_id.label == 'INSTRUKSI BUPATI'"></p>

                <!-- Jika Sekda 1 2 3 -->
                <!-- <p style="text-align:center; margin:0; line-height: 3">SEKRETARIS DAERAH KABUPATEN BANDUNG,</p> -->
                <p style="text-align:center; margin:0; line-height: 3" v-if="document.document.document_type_id.label == 'KEPUTUSAN SEKDA'">SEKRETARIS DAERAH,</p>

                <!-- Menimbang -->
                <template v-if="document.document.document_type_id.label == 'INSTRUKSI BUPATI'">
                    <table class="table-naskah" v-if="document.document_considers.length > 1">
                        <tbody v-for="(value, key) in document.document_considers" :key="key">
                        <tr class="menimbang">
                            <td style="vertical-align: baseline;">
                                <p class="instruksi" :style="{ 
                                    'margin-top': value.margin_top + 'px',  
                                    'margin-bottom': value.margin_bottom + 'px', 
                                    'line-height' : value.margin_left == 0 ? 1.6:value.margin_left + '!important'
                                }" style=" text-align: justify;text-justify: inter-word;" v-html="value.description"></p>
                            </td>
                        </tr>
                        <p v-html="addLineBreaks(value.margin_bottom)"></p>
                        </tbody>
                        
                    </table>
                    <table class="table-naskah" v-else>
                        <tr class="menimbang">
                            <td colspan="2" 
                                style="vertical-align: baseline;">
                                <p class="instruksi"
                                :style="{ 
                                    'margin-top': document.document_considers[0].margin_top + 'px',  
                                    'margin-bottom': document.document_considers[0].margin_bottom + 'px', 
                                    'line-height' : !document.document_considers[0].margin_left ? 1.6 : document.document_considers[0].margin_left == 0 ? 1.6 : ocument.document_considers[0].margin_left + '!important'
                                }" style=" text-align: justify;text-justify: inter-word;" v-html="document.document_considers[0].description"></p>
                            </td>
                        </tr>
                    </table>
                </template>
                <template v-else>
                    <table class="table-naskah" v-if="document.document_considers.length > 1">
                        <tbody v-for="(value, key) in document.document_considers" :key="key">
                        <tr class="menimbang">
                            <td style="vertical-align: baseline;width: 136px">{{ key == 0 ? 'Menimbang' : '' }}</td>
                            <td style="vertical-align: baseline;width: 18.8px">{{ key == 0 ? ':' : '' }}</td>
                            <td style="vertical-align: baseline;width: 37.7px">{{ changeListNumber(key) }}.</td>
                            <td style="vertical-align: baseline;">
                                <p :style="{ 
                                    'margin-top': value.margin_top + 'px',  
                                    'margin-bottom': value.margin_bottom + 'px', 
                                    'line-height' : value.margin_left == 0 ? 1.6:value.margin_left + '!important'
                                }" style=" text-align: justify;text-justify: inter-word;" v-html="value.description"></p>
                            </td>
                        </tr>
                        <p v-html="addLineBreaks(value.margin_bottom)"></p>
                        </tbody>
                        
                    </table>
                    <table class="table-naskah" v-else>
                        <tr class="menimbang">
                            <td style="vertical-align: baseline;width: 136px">Menimbang</td>
                            <td style="vertical-align: baseline;width: 18.8px">:</td>
                            <td colspan="2" 
                                style="vertical-align: baseline;">
                                <p
                                :style="{ 
                                    'margin-top': document.document_considers[0].margin_top + 'px',  
                                    'margin-bottom': document.document_considers[0].margin_bottom + 'px', 
                                    'line-height' : !document.document_considers[0].margin_left ? 1.6 : document.document_considers[0].margin_left == 0 ? 1.6 : document.document_considers[0].margin_left + '!important'
                                }" style=" text-align: justify;text-justify: inter-word;" v-html="document.document_considers[0].description"></p>
                            </td>
                        </tr>
                    </table>
                </template>
                <!-- Menimbang -->

                <!-- Mengingat -->
                <template v-if="document.document.document_type_id.label == 'INSTRUKSI BUPATI'">
                    <p style="margin-bottom:0; line-height: 3; padding-bottom: 5px;">Dengan ini menginstruksikan kepada:</p>
                    <table class="table-naskah" v-if="document.document_remembers.length > 1">
                        <tbody v-for="(value, key) in document.document_remembers" :key="key">
                            <tr class="mengingat">
                                <td style="vertical-align: baseline;width: 136px">{{ key == 0 ? 'Kepada' : '' }}</td>
                                <td style="vertical-align: baseline;width: 18.8px">{{ key == 0 ? ':' : '' }}</td>
                                <td style="vertical-align: baseline;width: 37.7px">{{ key + 1 }}.</td>
                                <td style="vertical-align: baseline;">
                                    <p :style="{ 
                                        'margin-top': value.margin_top + 'px', 
                                        'margin-bottom': value.margin_bottom + 'px',
                                        'line-height' : value.margin_left == 0 ? 1.6:value.margin_left + '!important'
                                    }" style=" text-align: justify;text-justify: inter-word;" v-html="value.description"></p>
                                </td>
                            </tr>
                            <p v-html="addLineBreaks(value.margin_bottom)"></p>
                        </tbody>
                    </table>
                    <table class="table-naskah" v-else>
                        <tr class="mengingat">
                            <td style="vertical-align: baseline;width: 136px">Kepada</td>
                            <td style="vertical-align: baseline;width: 18.8px">:</td>
                            <td colspan="2"  style="vertical-align: baseline;">
                                <p :style="{ 
                                    'margin-top': document.document_remembers[0].margin_top + 'px', 
                                    'margin-left': document.document_remembers[0].margin_left + 'px', 
                                    'margin-right': document.document_remembers[0].margin_right + 'px', 
                                    'margin-bottom': document.document_remembers[0].margin_bottom + 'px' 
                                }" style=" text-align: justify;text-justify: inter-word;" v-html="document.document_remembers[0].description"></p>
                            </td>
                        </tr>
                    </table>
                </template>
                <template v-else>
                    <table class="table-naskah" v-if="document.document_remembers.length > 1">
                        <tbody v-for="(value, key) in document.document_remembers" :key="key">
                            
                            <tr class="mengingat">
                                <td style="vertical-align: baseline;width: 136px">{{ key == 0 ? 'Mengingat' : '' }}</td>
                                <td style="vertical-align: baseline;width: 18.8px">{{ key == 0 ? ':' : '' }}</td>
                                <td style="vertical-align: baseline;width: 37.7px">{{ key + 1 }}.</td>
                                <td style="vertical-align: baseline;">
                                    <p :style="{ 
                                        'margin-top': value.margin_top + 'px', 
                                        'margin-bottom': value.margin_bottom + 'px',
                                        'line-height' : value.margin_left == 0 ? 1.6:value.margin_left + '!important'
                                    }" style=" text-align: justify;text-justify: inter-word;" v-html="value.description"></p>
                                </td>
                            </tr>
                            <p v-html="addLineBreaks(value.margin_bottom)"></p>
                        </tbody>
                    </table>
                    <table class="table-naskah" v-else>
                        <tr class="mengingat">
                            <td style="vertical-align: baseline;width: 136px">Mengingat</td>
                            <td style="vertical-align: baseline;width: 18.8px">:</td>
                            <td colspan="2"  style="vertical-align: baseline;">
                                <p :style="{ 
                                    'margin-top': document.document_remembers[0].margin_top + 'px', 
                                    'margin-left': document.document_remembers[0].margin_left + 'px', 
                                    'margin-right': document.document_remembers[0].margin_right + 'px', 
                                    'margin-bottom': document.document_remembers[0].margin_bottom + 'px' 
                                }" style=" text-align: justify;text-justify: inter-word;" v-html="document.document_remembers[0].description"></p>
                            </td>
                        </tr>
                    </table>
                </template>
                <!-- Mengingat -->

                <!-- Memperhatikan -->
                <div v-if="document.document_notices.length > 0">
                    <table class="table-naskah" v-if="document.document_notices.length > 1">
                        <tbody v-for="(value, key) in document.document_notices" :key="key">
                            
                            <tr class="memperhatikan">
                                <td style="vertical-align: baseline;width: 136px">{{ key == 0 ? 'Memperhatikan' : '' }}</td>
                                <td style="vertical-align: baseline;width: 18.8px">{{ key == 0 ? ':' : '' }}</td>
                                <td style="vertical-align: baseline;width: 37.7px">{{ key + 1 }}.</td>
                                <td style="vertical-align: baseline;">
                                    <p :style="{ 
                                        'margin-top': value.margin_top + 'px',  
                                        'margin-bottom': value.margin_bottom + 'px' ,
                                        'line-height' : value.margin_left == 0 ? 1.6:value.margin_left + '!important'
                                    }" style="text-align: justify;text-justify: inter-word;" v-html="value.description"></p>
                                </td>
                            </tr>
                            <p v-html="addLineBreaks(value.margin_bottom)"></p>
                        </tbody>
                    </table>
                    <table class="table-naskah" v-else>
                        <tr class="memperhatikan">
                            <td style="vertical-align: baseline;width: 136px">Memperhatikan</td>
                            <td style="vertical-align: baseline;width: 18.8px">:</td>
                            <td colspan="2"  style="vertical-align: baseline;">
                                <p :style="{ 
                                    'margin-top': document.document_notices[0].margin_top + 'px', 
                                    'margin-left': document.document_notices[0].margin_left + 'px', 
                                    'margin-right': document.document_notices[0].margin_right + 'px', 
                                    'margin-bottom': document.document_notices[0].margin_bottom + 'px' 
                                }" style="text-align: justify;text-justify: inter-word;" v-html="document.document_notices[0].description"></p>
                            </td>
                        </tr>
                    </table>
                </div>
                <!-- Memperhatikan -->

                <!-- Memutuskan -->
                <template v-if="document.document.document_type_id.label == 'INSTRUKSI BUPATI'">
                    <table class="table-naskah" width="100%">
                        <tr>
                            <td style="vertical-align: baseline;width: 136px">Untuk</td>
                            <td style="vertical-align: baseline;width: 18.8px">:</td>
                            <td style="vertical-align: baseline;width: 37.7px"></td>
                            <td style="vertical-align: baseline;"></td>
                        </tr>
                        <tbody v-for="(value, key) in document.document_decisions" :key="key">
                        <tr 
                        :class="[hasNoLiElement(value.description) && value.extend_form.length === 0 ? 'memutuskan' : 'memutuskan-extend']" 
                        :id="'tr-' + key">
                            <td style="vertical-align: baseline;width: 136px">{{ pembilang(key + 1, true) }}</td>
                            <td style="vertical-align: baseline;width: 18.8px">:</td>
                            <td colspan="2" style="vertical-align: baseline;">
                                <p :style="{ 
                                        'margin-top': value.margin_top + 'px',  
                                        'margin-bottom': value.margin_bottom + 'px' ,
                                        'line-height' : value.margin_left == 0 ? 1.6:value.margin_left + '!important'
                                    }" 
                                    style="text-align: justify;text-justify: inter-word;" 
                                    v-html="value.description"></p>

                            
                                    <!-- table -->
                                    <div v-if="value.extend_form.length > 0" class="mt-2 mb-2">
                                        
                                        <div v-for="(value, key) in value.extend_form" :key="key">
                                            <div class="paragraf" v-if="value.type == 'paragraf'">
                                                <p style="text-align:center; line-height: 1.6;
                                                margin-left: 22.6px;
                                                margin-right: 22.6px;" 
                                                class="pb-3 pt-3">
                                                    {{ value.text }}
                                                </p>
                                            </div>
                                            <div v-else>
                                                <table style="vertical-align: bottom;" :style="[
                                                    value.align_table == 'kanan' ? { 'margin-right': '0', 'margin-left': 'auto' } : '',
                                                    value.align_table == 'kiri' ? { 'margin-right': 'auto', 'margin-left': '0' } : '',
                                                    value.align_table == 'tengah' ? { 'margin-right': 'auto', 'margin-left': 'auto' } : '',
                                                    value.align_table == 'penuh' ? { 'width': '100%' } : '',
                                                ]">
                                                    <tr class="tabel" v-for="(row, rowIndex) in value.tableData" :key="rowIndex">
                                                        <td :style="[
                                                                rowIndex == 0 && value.header_center == '1' ? {'text-align': 'center'} : {'text-align': 'justify', 'vertical-align': 'top'}, 
                                                                value.type_border == '1' ? {'border': '0.1px solid black'} : { 'border': 'none' },
                                                                cell.long ? { 'width': cell.long + 'px' } : null
                                                            ]" class="p-1" v-for="(cell, colIndex) in row" :key="colIndex">
                                                            <div v-html="cell.value "></div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- table -->
                            </td>
                        </tr>
                        <p v-html="addLineBreaks(value.margin_bottom)"></p>
                        </tbody>
                    </table>
                </template>
                <template v-else-if="document.document.document_type_id.label == 'PERATURAN BUPATI'">
                    <table class="table-naskah" width="100%">
                        <tr>
                            <td colspan="4">
                                <p style="text-align:center; margin:0; line-height: 3">MEMUTUSKAN:</p>
                            </td>
                        </tr>
                        <tr 
                            :class="['memutuskan-extend']" 
                            :id="'tr-' + 0">
                                <td style="vertical-align: baseline;width: 136px">Menetapkan</td>
                                <td style="vertical-align: baseline;width: 18.8px">:</td>
                                <td colspan="2" style="vertical-align: baseline;">
                                    <p :style="{ 
                                            'margin-top': '0px',  
                                            'margin-bottom': '0px' ,
                                            'line-height' :  1.6,
                                        }" 
                                        style="text-align: justify;text-justify: inter-word;" v-html="'PERATURAN BUPATI TENTANG ' + document.document.title"></p>
                                </td>
                        </tr>
                        <tbody v-for="(value, key) in document.document_decisions" :key="key">
                            <template>
                                <tr>
                                    <td style="vertical-align: baseline;width: 136px"></td>
                                    <td style="vertical-align: baseline;width: 18.8px"></td>
                                    <td colspan="2" style="vertical-align: baseline;width: 37.7px;text-align: center;line-height: 1;padding-top: 1rem;">BAB {{ romanize(key + 1) }}</td>
                                </tr>
                                <tr
                                    :class="[hasNoLiElement(value.description) && value.extend_form.length === 0 ? 'memutuskan' : 'memutuskan-extend']" 
                                    :id="'tr-' + key">
                                        <td style="vertical-align: baseline;width: 136px"></td>
                                        <td style="vertical-align: baseline;width: 18.8px"></td>
                                        <td colspan="2" style="vertical-align: baseline;">
                                            <p :style="{ 
                                                    'margin-top': value.margin_top + 'px',  
                                                    'margin-bottom': value.margin_bottom + 'px' ,
                                                    'line-height' : value.margin_left == 0 ? 1.6:value.margin_left + '!important'
                                                }" 
                                                style="text-align: center;text-justify: inter-word;" 
                                                v-html="value.description"></p>
                                                <!-- table -->
                                                <div v-if="value.extend_form.length > 0" class="mt-2 mb-2"> 
                                                    <div v-for="(value, key_extend) in value.extend_form" :key="key_extend">

                                                        <!-- untuk bagian -->
                                                        <div class="paragraf" v-if="value.type == 'bagian'" style="padding-bottom: 20px; padding-top: 20px">
                                                            <p style="text-align:center;line-height: 1.6">Bagian {{ getNumberBagian(key, key_extend) }}</p>
                                                            <p style="text-align:center;line-height: 1.6" v-html="value.text"></p>
                                                        </div>

                                                        <!-- untuk paragraf -->
                                                        <div class="" v-if="value.type == 'paragraf'" style="padding-bottom: 20px;">
                                                            <p style="text-align:center;line-height: 1.6">Paragraf {{ getNumberParagraf(key, key_extend) }}</p>
                                                            <p style="text-align:center;line-height: 1.6" v-html="value.text"></p>
                                                        </div>

                                                        <!-- untuk pasal -->
                                                        <div class="" v-if="value.type == 'pasal'">
                                                            <p style="text-align:center;line-height: 1.6;">Pasal {{ getNumberPasal(key, key_extend) }}</p>
                                                            <p style="text-align:justify;line-height: 1.6;" class="pb-3 pt-3" v-html="value.text"></p>
                                                        </div>

                                                        <!-- untuk pasal + ayat -->
                                                        <div class="paragraf" v-if="value.type == 'pasal-ayat'">
                                                            <p style="text-align:center;line-height: 1.6;">Pasal {{ getNumberPasal(key, key_extend) }}</p>
                                                            <table class="table-naskah">
                                                                <tbody v-for="(ayat, indexAyat) in value.ayat" :key="indexAyat">
                                                                    <tr class="menimbang">
                                                                        <td style="vertical-align: baseline;width: 37.7px">{{ '(' + (indexAyat+1) + ')' }}</td>
                                                                        <td style="vertical-align: baseline;">
                                                                            <p :style="{ 
                                                                                'margin-top':'0px',  
                                                                                'margin-bottom': '0px', 
                                                                                'line-height' : 1.6,
                                                                            }" style=" text-align: justify;text-justify: inter-word;" v-html="ayat.value"></p>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <!-- <div v-else>
                                                            <table style="vertical-align: bottom;" :style="[
                                                                value.align_table == 'kanan' ? { 'margin-right': '0', 'margin-left': 'auto' } : '',
                                                                value.align_table == 'kiri' ? { 'margin-right': 'auto', 'margin-left': '0' } : '',
                                                                value.align_table == 'tengah' ? { 'margin-right': 'auto', 'margin-left': 'auto' } : '',
                                                                value.align_table == 'penuh' ? { 'width': '100%' } : '',
                                                            ]">
                                                                <tr class="tabel" v-for="(row, rowIndex) in value.tableData" :key="rowIndex">
                                                                    <td :style="[
                                                                            rowIndex == 0 && value.header_center == '1' ? {'text-align': 'center'} : {'text-align': 'justify', 'vertical-align': 'top'}, 
                                                                            value.type_border == '1' ? {'border': '0.1px solid black'} : { 'border': 'none' },
                                                                            cell.long ? { 'width': cell.long + 'px' } : null
                                                                        ]" class="p-1" v-for="(cell, colIndex) in row" :key="colIndex">
                                                                        <div v-html="cell.value "></div>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div> -->
                                                    </div>
                                                </div>
                                                <!-- table -->
                                        </td>
                                </tr>
                                <p v-html="addLineBreaks(value.margin_bottom)"></p>
                            </template>
                        </tbody>
                    </table>
                </template>
                <template v-else>
                    <table class="table-naskah" width="100%">
                        <tr>
                            <td colspan="4">
                                <p style="text-align:center; margin:0; line-height: 3">MEMUTUSKAN:</p>
                            </td>
                        </tr>
                        <tr>
                            <td style="vertical-align: baseline;width: 136px">Menetapkan</td>
                            <td style="vertical-align: baseline;width: 18.8px">:</td>
                            <td style="vertical-align: baseline;width: 37.7px"></td>
                            <td style="vertical-align: baseline;"></td>
                        </tr>
                        <tbody v-for="(value, key) in document.document_decisions" :key="key">
                        <tr 
                        :class="[hasNoLiElement(value.description) && value.extend_form.length === 0 ? 'memutuskan' : 'memutuskan-extend']" 
                        :id="'tr-' + key">
                            <td style="vertical-align: baseline;width: 136px">{{ pembilang(key + 1, true) }}</td>
                            <td style="vertical-align: baseline;width: 18.8px">:</td>
                            <td colspan="2" style="vertical-align: baseline;">
                                <p :style="{ 
                                        'margin-top': value.margin_top + 'px',  
                                        'margin-bottom': value.margin_bottom + 'px' ,
                                        'line-height' : value.margin_left == 0 ? 1.6:value.margin_left + '!important'
                                    }" 
                                    style="text-align: justify;text-justify: inter-word;" 
                                    v-html="value.description"></p>

                            
                                    <!-- table -->
                                    <div v-if="value.extend_form.length > 0" class="mt-2 mb-2">
                                        
                                        <div v-for="(value, key) in value.extend_form" :key="key">
                                            <div class="paragraf" v-if="value.type == 'paragraf'">
                                                <p style="text-align:center; line-height: 1.6;
                                                margin-left: 22.6px;
                                                margin-right: 22.6px;" 
                                                class="pb-3 pt-3">
                                                    {{ value.text }}
                                                </p>
                                            </div>
                                            <div v-else>
                                                <table style="vertical-align: bottom;" :style="[
                                                    value.align_table == 'kanan' ? { 'margin-right': '0', 'margin-left': 'auto' } : '',
                                                    value.align_table == 'kiri' ? { 'margin-right': 'auto', 'margin-left': '0' } : '',
                                                    value.align_table == 'tengah' ? { 'margin-right': 'auto', 'margin-left': 'auto' } : '',
                                                    value.align_table == 'penuh' ? { 'width': '100%' } : '',
                                                ]">
                                                    <tr class="tabel" v-for="(row, rowIndex) in value.tableData" :key="rowIndex">
                                                        <td :style="[
                                                                rowIndex == 0 && value.header_center == '1' ? {'text-align': 'center'} : {'text-align': 'justify', 'vertical-align': 'top'}, 
                                                                value.type_border == '1' ? {'border': '0.1px solid black'} : { 'border': 'none' },
                                                                cell.long ? { 'width': cell.long + 'px' } : null
                                                            ]" class="p-1" v-for="(cell, colIndex) in row" :key="colIndex">
                                                            <div v-html="cell.value "></div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- table -->
                            </td>
                        </tr>
                        <p v-html="addLineBreaks(value.margin_bottom)"></p>
                        </tbody>
                    </table>
                </template>

                <!-- Memutuskan -->
                <div class="ditetapkan">

                        <!-- Jika Bupati -->
                        <FooterInstruksiBupati v-if="document.document.document_type_id.label == 'INSTRUKSI BUPATI'"></FooterInstruksiBupati>
                        <!-- End Jika Bupati -->
                    
                        <!-- Jika Bupati -->
                        <FooterBupati v-if="document.document.document_type_id.label == 'KEPUTUSAN BUPATI'"></FooterBupati>
                        <!-- End Jika Bupati -->

                        <!-- Jika Bupati -->
                        <FooterPeraturanBupati v-if="document.document.document_type_id.label == 'PERATURAN BUPATI'"></FooterPeraturanBupati>
                        <!-- End Jika Bupati -->
                        
                        <!-- Jika Sekda Versi BIASA  -->
                        <FooterSekda v-else-if="document.document.document_type_id.label == 'KEPUTUSAN SEKDA'"></FooterSekda>
                        <!-- End Sekda Versi BIASA -->

                        <!-- Jika Sekda Versi PENGGUNA ANGGARAN  -->
                        <FooterSekdaAnggaran v-else-if="document.document.document_type_id.label == 'KEPUTUSAN SEKDA PENGGUNA ANGGARAN'"></FooterSekdaAnggaran>
                        <!-- End Sekda Versi PENGGUNA ANGGARAN -->

                        <!-- Jika Sekda Versi PENGGUNA BARANG  -->
                        <FooterSekdaBarang v-else-if="document.document.document_type_id.label == 'KEPUTUSAN SEKDA PENGGUNA BARANG'"></FooterSekdaBarang>
                        <!-- End Sekda Versi PENGGUNA ANGGARAN -->
                   
                </div>

                <!-- Tembusan page -->
                <div class="tembusan">
                    <div class="row mt-3 mr-2" v-if="document.document_copies.length > 0">
                        <div class="col-8">
                            <p style="margin:0;line-height: 1.6;"><u>Tembusan</u>, disampaikan kepada: </p>
                            <table>
                                <tr v-for="(value, index) in document.document_copies" :key="index">
                                    <td style="padding-right: 10px; vertical-align: top;">{{ index == 0 ? 'Yth.' : '' }}</td>
                                    <td style="vertical-align: top;padding-right: 10px;">{{ index + 1 }}.</td>
                                    <td style="text-align: justify;">{{ value.copy_text }}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td style="border-top: 1px solid black;"></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>

                <!-- <div class="ttd_bupati">
                    <div v-for="(attachment, index) in document.document_attachments" :key="attachment.id">
                        <div class="row" v-if="!attachment.is_file">
                            <div class="col-3">&nbsp;</div>
                            <div class="col-9">
                                <p>LAMPIRAN {{ romanize(index +1) }} KEPUTUSAN BUPATI BANDUNG</p>
                                <table class="table-naskah">
                                    <tr>
                                        <td style="vertical-align: baseline;width: 136px">NOMOR</td>
                                        <td style="vertical-align: baseline;width: 18.8px">:</td>
                                        <td style="vertical-align: baseline;">{{ document.document.document_number ? document.document.document_number : '' }}</td>
                                    </tr>
                                    <tr>
                                        <td style="vertical-align: baseline;width: 136px">TANGGAL</td>
                                        <td style="vertical-align: baseline;width: 18.8px">:</td>
                                        <td style="vertical-align: baseline;">{{ formatDate(document.document.date) }}</td>
                                    </tr>
                                    <tr>
                                        <td style="vertical-align: baseline;width: 136px">TENTANG</td>
                                        <td style="vertical-align: baseline;width: 18.8px">:</td>
                                        <td style="vertical-align: baseline;text-align: justify;text-justify: inter-word;">{{ document.document.title }}</td>
                                    </tr>
                                </table>    
                            </div>
                            <p style="text-align:center; margin:30px">
                                {{ attachment.tittle }}
                            </p>
                            <p style="text-align: justify;text-justify: inter-word; margin-top: 30px;" v-html="attachment.description"></p>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="ttd_bupati">
                    <p style="text-align:center; margin:0; line-height: 3">DAFTAR VERFIKATOR</p>
                    <table class="table" style="color:black !important">
                        <tr>
                            <td>Posisi</td>
                            <td>Nama</td>
                            <td>Tanggal Approve</td>
                        </tr>
                        <tr>
                            <td>Legal Drafter</td>
                            <td>{{ verificator.legal_drafter_verified ? verificator.legal_drafter_verified.full_name : '-' }}</td>
                            <td>{{ verificator.legal_drafter_verified_at ? verificator.legal_drafter_verified_at : '-' }}</td>
                        </tr>
                        <tr>
                            <td>Suncang</td>
                            <td>{{ verificator.suncang_verified ? verificator.suncang_verified.full_name : '-' }}</td>
                            <td>{{ verificator.suncang_verified_at ? verificator.suncang_verified_at : '-' }}</td>
                        </tr>
                        <tr>
                            <td>Kasubag</td>
                            <td>{{ verificator.kasubag_verified ? verificator.kasubag_verified.full_name : '-' }}</td>
                            <td>{{ verificator.kasubag_verified_at ? verificator.kasubag_verified_at : '-' }}</td>
                        </tr>
                        <tr>
                            <td>Kabag</td>
                            <td>{{ verificator.kabag_verified ? verificator.kabag_verified.full_name : '-' }}</td>
                            <td>{{ verificator.kabag_verified_at ? verificator.kabag_verified_at : '-' }}</td>
                        </tr>
                        <tr>
                            <td>Asisten</td>
                            <td>{{ verificator.asistant_verified ? verificator.asistant_verified.full_name : '-' }}</td>
                            <td>{{ verificator.asistant_verified_at ? verificator.asistant_verified_at : '-' }}</td>
                        </tr>
                        <tr>
                            <td>Sekda</td>
                            <td>{{ verificator.sekda_verified ? verificator.sekda_verified.full_name : '-' }}</td>
                            <td>{{ verificator.sekda_verified_at ? verificator.sekda_verified_at : '-' }}</td>
                        </tr>
                        <tr>
                            <td>Bupati</td>
                            <td>{{ verificator.bupati_verified ? verificator.bupati_verified.full_name : '-' }}</td>
                            <td>{{ verificator.bupati_verified_at ? verificator.bupati_verified_at : '-' }}</td>
                        </tr>
                    </table>
                </div> -->
                </div>
                
            </div>
        </div>
        <CButton class="mb-3 mr-3 btn btn-success"
            @click="preview()"
            >
            Preview PDF
        </CButton>
        <CButton class="mb-3 btn btn-primary"
            @click="exportPDF()"
            >
            Generate PDF
        </CButton>
        <div v-if="progress.percentage > 0 && !draft_sk">
            <p>{{ progress.byte_transfered }} / {{ progress.total_byte }}</p>
            <div class="progress">
                <div class="progress-bar" role="progressbar" :style="{ width: progress.percentage + '%' }" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{ progress.percentage }}%</div>
            </div>
        </div>
        <div v-if="draft_sk">
            <iframe :src="draft_sk" width="100%" height="500" type="application/pdf"></iframe>
        </div>
    </div>
</template>

<script> 
    import html2pdf from "html2pdf.js";
    import firebase from "firebase/compat/app";
    import addFontBookmanOldStyle from '@/utils/html2pdf.fonts.js';
    import HeaderBupati from '@/views/menu/DocumentDetail/Keputusan/HeaderBupati.vue';
    import HeaderPeraturanBupati from '@/views/menu/DocumentDetail/Keputusan/HeaderPeraturanBupati.vue';
    import HeaderInstruksiBupati from '@/views/menu/DocumentDetail/Keputusan/HeaderInstruksiBupati.vue';
    import HeaderSekda from '@/views/menu/DocumentDetail/Keputusan/HeaderSekda.vue';
    import HeaderSekdaAnggaran from '@/views/menu/DocumentDetail/Keputusan/HeaderSekdaAnggaran.vue';
    import HeaderSekdaBarang from '@/views/menu/DocumentDetail/Keputusan/HeaderSekdaBarang.vue';

    import FooterBupati from '@/views/menu/DocumentDetail/Keputusan/FooterBupati.vue';
    import FooterPeraturanBupati from '@/views/menu/DocumentDetail/Keputusan/FooterPeraturanBupati.vue';
    import FooterInstruksiBupati from '@/views/menu/DocumentDetail/Keputusan/FooterInstruksiBupati.vue';
    import FooterSekda from '@/views/menu/DocumentDetail/Keputusan/FooterSekda.vue';
    import FooterSekdaAnggaran from '@/views/menu/DocumentDetail/Keputusan/FooterSekdaAnggaran.vue';
    import FooterSekdaBarang from '@/views/menu/DocumentDetail/Keputusan/FooterSekdaBarang.vue';
    import {font} from '@/assets/fonts/bookman-old-style-normal.js';
    export default {

        components: {
            HeaderBupati, // Register the HeaderBupati component for use within SomeOtherComponent
            HeaderInstruksiBupati,
            HeaderPeraturanBupati,
            HeaderSekda,
            HeaderSekdaAnggaran,
            HeaderSekdaBarang,
            FooterBupati, // Register the FooterBupati component for use within SomeOtherComponent
            FooterPeraturanBupati,
            FooterInstruksiBupati,
            FooterSekda,
            FooterSekdaAnggaran,
            FooterSekdaBarang,
        },
        props: ['clickedNext', 'currentStep'],
        data() {
            return {
                draft_sk: "",
                document: {},
                progress: {},
                verificator: {}
            }
        },
        methods: {
            getNumberParagraf(babIndex, indexExtend) {
                // Filter hanya elemen dengan type === 'bagian' di BAB saat ini
                const bagianList = this.document.document_decisions[babIndex].extend_form.filter(f => f.type === 'paragraf');

                // Cari indeks elemen saat ini di daftar bagianList
                const bagianIndex = bagianList.findIndex((item, index) => {
                    // Cocokkan elemen extend_form dengan indexExtend
                    return this.document.document_decisions[babIndex].extend_form.indexOf(item) === indexExtend;
                });

                // Jika elemen ditemukan, kembalikan indeks + 1 (agar dimulai dari 1)
                return bagianIndex !== -1 ? bagianIndex + 1 : 0;
            },
            getNumberPasal(babIndex, pasalIndex) {
                let totalPasal = 0;

                // Loop hingga BAB saat ini
                for (let i = 0; i < babIndex; i++) {
                    // Tambahkan jumlah elemen dengan type === 'paragraf' di setiap BAB sebelumnya
                    totalPasal += this.document.document_decisions[i].extend_form.filter(item => (item.type === 'pasal' || item.type === 'pasal-ayat')).length;
                }

                // Hitung hanya elemen bertipe 'paragraf' di BAB saat ini
                const currentPasalList = this.document.document_decisions[babIndex].extend_form.filter(item =>(item.type === 'pasal' || item.type === 'pasal-ayat'));

                // Cari indeks elemen pasal di daftar elemen bertipe 'paragraf'
                const filteredPasalIndex = currentPasalList.findIndex((item) => {
                    return this.document.document_decisions[babIndex].extend_form.indexOf(item) === pasalIndex;
                });

                // Tambahkan indeks pasal saat ini (jika ditemukan)
                if (filteredPasalIndex !== -1) {
                    totalPasal += filteredPasalIndex + 1; // Indeks dimulai dari 0, jadi tambahkan 1
                }

                return totalPasal; // Kembalikan nomor pasal
            },
            pembilangKecil(nilai, isPutusan){
                nilai = Math.abs(nilai);
                var simpanNilaiBagi=0;
                var huruf = ["", "satu", "dua", "tiga", "empat", "lima", "enam", "tujuh", "delapan", "sebilan", "sebuluh", "sebelas"];
                var temp="";
            
                if (nilai < 12) {
                    temp = ""+huruf[nilai];
                }
                else if (nilai <20) {
                    console.log(nilai - 10, "nilai")
                    temp = this.pembilang(nilai - 10) + " belas";
                }
                else if (nilai < 100) {
                    simpanNilaiBagi = Math.floor(nilai/10);
                    temp = this.pembilang(simpanNilaiBagi)+" puluh"+ this.pembilang(nilai % 10);
                }
                else if (nilai < 200) {
                    temp = " seratus" + this.pembilang(nilai - 100);
                }
                else if (nilai < 1000) {
                    simpanNilaiBagi = Math.floor(nilai/100);
                    temp = this.pembilang(simpanNilaiBagi) + " ratus" + this.pembilang(nilai % 100);
                }
                else if (nilai < 2000) {
                    temp = " seribu" + this.pembilang(nilai - 1000);
                }
                else if (nilai < 1000000) {
                    simpanNilaiBagi = Math.floor(nilai/1000);
                    temp = this.pembilang(simpanNilaiBagi) + " ribu" + this.pembilang(nilai % 1000);
                } 
                else if (nilai < 1000000000) {
                    simpanNilaiBagi = Math.floor(nilai/1000000);
                    temp =this.pembilang(simpanNilaiBagi) + " juta" + this.pembilang(nilai % 1000000);
                } 
                else if (nilai < 1000000000000) {
                    simpanNilaiBagi = Math.floor(nilai/1000000000);
                    temp = this.pembilang(simpanNilaiBagi) + " miliar" + this.pembilang(nilai % 1000000000);
                } 
                else if (nilai < 1000000000000000) {
                    simpanNilaiBagi = Math.floor(nilai/1000000000000);
                    temp = this.pembilang(nilai/1000000000000) + " triliun" + this.pembilang(nilai % 1000000000000);
                }
            
                if (isPutusan) {
                    return 'Ke'+temp;
                } else {
                    return temp;
                }
                
            },
            getNumberBagian(babIndex, indexExtend) {
                // Filter hanya elemen dengan type === 'bagian' di BAB saat ini
                const bagianList = this.document.document_decisions[babIndex].extend_form.filter(f => f.type === 'bagian');

                // Cari indeks elemen saat ini di daftar bagianList
                const bagianIndex = bagianList.findIndex((item, index) => {
                    // Cocokkan elemen extend_form dengan indexExtend
                    return this.document.document_decisions[babIndex].extend_form.indexOf(item) === indexExtend;
                });

                // Jika elemen ditemukan, kembalikan indeks + 1 (agar dimulai dari 1)
                return this.pembilangKecil(bagianIndex !== -1 ? bagianIndex + 1 : 0, true);
            },
            addLineBreaks(numLineBreaks) {
        // Add line breaks to the text based on the value of numLineBreaks
                return "<br>".repeat(numLineBreaks);
                },
                hasNoLiElement(key) {
                    return !(key.includes("<li>") || key.includes("<ul>") || key.includes("<ol>"));
                    },
            romanize(num) {
                var lookup = {M:1000,CM:900,D:500,CD:400,C:100,XC:90,L:50,XL:40,X:10,IX:9,V:5,IV:4,I:1},roman = '',i;
                for ( i in lookup ) {
                    while ( num >= lookup[i] ) {
                    roman += i;
                    num -= lookup[i];
                    }
                }
                return roman; 
            },
            uploadFileBlob(file, filename) {
                var uniqueId = Date.now();
                var storageRef = firebase
                    .storage()
                    .ref("naskah/" + uniqueId + filename)
                    .put(file);

                storageRef.on(
                    "state_changed",
                    (snapshot) => {
                        this.progress = {
                            status: snapshot.state,
                            byte_transfered: snapshot.bytesTransferred,
                            total_byte: snapshot.totalBytes,
                            percentage: ((snapshot.bytesTransferred / snapshot.totalBytes) * 100).toFixed()
                        }
                    },
                    (error) => {
                        reject(error.message)
                        this.$swal.fire({
                            icon: 'danger',
                            text: 'Gagal generate file PDF ' . error.message
                        })
                    },
                    () => {
                        storageRef.snapshot.ref.getDownloadURL().then((resp) => {
                            this.$swal.fire({
                                icon: 'success',
                                text: 'Sukses generate file PDF '
                            })
                            this.draft_sk = resp;
                        });
                    }
                );
            },
            formatDate(date) {
                return this.$moment(date).format("Do MMMM YYYY");
            },
            leaving: function () {
                alert("Leaving...");
            },
            pembilang(nilai, isPutusan){
                nilai = Math.abs(nilai);
                var simpanNilaiBagi=0;
                var huruf = ["", "SATU", "DUA", "TIGA", "EMPAT", "LIMA", "ENAM", "TUJUH", "DELAPAN", "SEMBILAN", "SEPULUH", "SEBELAS"];
                var temp="";
            
                if (nilai < 12) {
                    temp = ""+huruf[nilai];
                }
                else if (nilai <20) {
                    console.log(nilai - 10, "nilai")
                    temp = this.pembilang(nilai - 10) + " BELAS";
                }
                else if (nilai < 100) {
                    simpanNilaiBagi = Math.floor(nilai/10);
                    temp = this.pembilang(simpanNilaiBagi)+" PULUH"+ this.pembilang(nilai % 10);
                }
                else if (nilai < 200) {
                    temp = " SERATUS" + this.pembilang(nilai - 100);
                }
                else if (nilai < 1000) {
                    simpanNilaiBagi = Math.floor(nilai/100);
                    temp = this.pembilang(simpanNilaiBagi) + " RATUS" + this.pembilang(nilai % 100);
                }
                else if (nilai < 2000) {
                    temp = " SERIBU" + this.pembilang(nilai - 1000);
                }
                else if (nilai < 1000000) {
                    simpanNilaiBagi = Math.floor(nilai/1000);
                    temp = this.pembilang(simpanNilaiBagi) + " RIBU" + this.pembilang(nilai % 1000);
                } 
                else if (nilai < 1000000000) {
                    simpanNilaiBagi = Math.floor(nilai/1000000);
                    temp =this.pembilang(simpanNilaiBagi) + " JUTA" + this.pembilang(nilai % 1000000);
                } 
                else if (nilai < 1000000000000) {
                    simpanNilaiBagi = Math.floor(nilai/1000000000);
                    temp = this.pembilang(simpanNilaiBagi) + " MILIAR" + this.pembilang(nilai % 1000000000);
                } 
                else if (nilai < 1000000000000000) {
                    simpanNilaiBagi = Math.floor(nilai/1000000000000);
                    temp = this.pembilang(nilai/1000000000000) + " TRILIUN" + this.pembilang(nilai % 1000000000000);
                }
            
                if (isPutusan) {
                    return 'KE'+temp;
                } else {
                    return temp;
                }
                
            },
            changeListNumber(index) {
                let alpabeth = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z' ];
                return alpabeth[index];
            },

            preview() {
                
                var opt = {
                    margin: [2, 0, 4, 0], //atas kiri bawah kanan 
                    filename: "draft.pdf",
                    image: { type: "jpeg", quality: 2 },
                    html2canvas: { scale: 2 },
                    jsPDF: { unit: "cm", format: "legal", orientation: "p" },
                    pagebreak: { avoid: [".menimbang",".mengingat",".memperhatikan",".memutuskan",".tembusan",".ditetapkan",".tabel",".paragraf","li"], before: [".ttd_bupati", ".peraturan-bupati"]},
                    // pagebreak: { avoid: ["tr", "li"], before: ".ttd_bupati"},
                };

                var element = document.getElementById("pdf-content");
                html2pdf()
                    .from(element)
                    .set(opt)
                    .toPdf()
                    .get('pdf')
                    .then((pdf) => {


                        pdf.addFileToVFS("bookman-old-style-normal.ttf", font);
                        pdf.addFont("bookman-old-style-normal.ttf", "BookmanOldStyle", "normal");
                        pdf.setFont("BookmanOldStyle");
                        
                        // Load file font
                        

                        try {
                            let totalPages = pdf.internal.getNumberOfPages();

                            for (let i = 1; i <= totalPages; i++) {

                                if (i == 1) {
                                    // Jika Sekda Pakai Kop nya Disini
                                    pdf.setPage(i);
                                    const bsre= new Image();
                                    bsre.src = require('@/assets/logo/bsre.png');
                                    pdf.addImage(bsre, 'png', 0, pdf.internal.pageSize.getHeight()-3.1, pdf.internal.pageSize.getWidth(), 4,'', 'SLOW'  );
                                    if(this.document.document.document_type_id.label == 'KEPUTUSAN SEKDA') {
                                        
                                        const img = new Image();
                                        img.src = require('@/assets/logo/sekdalogo.png');
                                        pdf.addImage(img, 'png', 0, 0.5, pdf.internal.pageSize.getWidth(), 4,'', 'SLOW'  );
                                        
                                    }
                                    
                                    
                                } else {
                                    pdf.setPage(i);
                                    pdf.setFontSize(12);
                                    pdf.setTextColor('#000000');
                                    pdf.text('- ' + i + ' -', (pdf.internal.pageSize.getWidth()/2.20), (1.5)); 
                                    const bsre= new Image();
                                    bsre.src = require('@/assets/logo/bsre.png');
                                    pdf.addImage(bsre, 'png', 0, pdf.internal.pageSize.getHeight()-3.1, pdf.internal.pageSize.getWidth(), 4,'', 'SLOW'  );  
                                }     
                            }

                            const perBlob = pdf.output('blob');
                            // this.uploadFileBlob(perBlob, opt.filename);
                        } catch (error) {
                            console.log(error);
                        }
                    })
                    .save();
            },

            exportPDF() {

                var opt = {
                    margin: [2, 0, 4, 0], //atas kiri bawah kanan 
                    filename: "draft.pdf",
                    image: { type: "jpeg", quality: 2 },
                    html2canvas: { scale: 2 },
                    jsPDF: { unit: "cm", format: "legal", orientation: "p" },
                    pagebreak: { avoid: [".menimbang",".mengingat",".memperhatikan",".memutuskan",".tembusan",".ditetapkan",".tabel",".paragraf","li"], before: ".ttd_bupati"},
                    // pagebreak: { avoid: ["tr", "li"], before: ".ttd_bupati"},
                };

                var element = document.getElementById("pdf-content");
                html2pdf()
                    .from(element)
                    .set(opt)
                    .toPdf()
                    .get('pdf')
                    .then((pdf) => {

                        pdf.addFileToVFS("bookman-old-style-normal.ttf", font);
                        pdf.addFont("bookman-old-style-normal.ttf", "BookmanOldStyle", "normal");
                        pdf.setFont("BookmanOldStyle");

                        try {
                            let totalPages = pdf.internal.getNumberOfPages();

                            for (let i = 1; i <= totalPages; i++) {

                                if (i == 1) {
                                    // Jika Sekda Pakai Kop nya Disini
                                    pdf.setPage(i);
                                    const bsre= new Image();
                                    bsre.src = require('@/assets/logo/bsre.png');
                                    pdf.addImage(bsre, 'png', 0, pdf.internal.pageSize.getHeight()-3.1, pdf.internal.pageSize.getWidth(), 4,'', 'SLOW'  );
                                    if(this.document.document.document_type_id.label == 'KEPUTUSAN SEKDA') {
                                        
                                        const img = new Image();
                                        img.src = require('@/assets/logo/sekdalogo.png');
                                        pdf.addImage(img, 'png', 0, 0.5, pdf.internal.pageSize.getWidth(), 4,'', 'SLOW'  );
                                        
                                    }
                                } else {
                                    pdf.setPage(i);
                                    pdf.setFontSize(12);
                                    pdf.setTextColor('#000000');
                                    pdf.text('- ' + i + ' -', (pdf.internal.pageSize.getWidth()/2.20), (1.5)); 
                                    const bsre= new Image();
                                    bsre.src = require('@/assets/logo/bsre.png');
                                    pdf.addImage(bsre, 'png', 0, pdf.internal.pageSize.getHeight()-3.1, pdf.internal.pageSize.getWidth(), 4,'', 'SLOW'  );  
                                }     
                            }

                            const perBlob = pdf.output('blob');
                            this.uploadFileBlob(perBlob, opt.filename);
                        } catch (error) {
                            console.log(error);
                        }
                    });
            },
            generateString() {
                const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let result = ' ';
                const charactersLength = characters.length;
                for ( let i = 0; i < 10; i++ ) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            }
        },
        watch: {
            currentStep(val) {
                if(!this.$v.invalid) {
                    this.$emit('can-continue', {value: true});
                } else {
                    this.$emit('can-continue', {value: false});
                }
            },
            draft_sk() {
                this.draft_sk = this.draft_sk ? this.draft_sk : "";
                this.document.document.draft_sk = this.draft_sk;
                this.$store.commit('document/setDocument', this.document.document);
            },
        },
        mounted() {
            // this.$emit('can-continue', {value: true});
            this.document = this.$store.state.document;
            console.log('doc', this.document)
            this.document.document.title = this.document.document.title.toString().replace(/<\/?p>/g, '');
            this.verificator = this.$store.state.document.verificator;
            setInterval(() => {
                if (this.draft_sk !== "") {
                    this.$emit('can-continue', {value: true});
                }
            }, 1000);
        },
    }
</script>

<style>
   /* @import url('https://fonts.googleapis.com/css2?family=Bookman+Old+Style'); */

   /* cyrillic */
   @font-face {
      font-family: 'Bookman Old Style';
      font-style: normal;
      font-weight: 400;
      src: url(https://fonts.gstatic.com/l/font?kit=MjQcmj92u_H-ux7vrpbgHLHRzMYMlfBkInIh27Te&skey=525c12327fbbcec7&v=v10) format('woff2');
      unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
   }
   /* greek */
   @font-face {
      font-family: 'Bookman Old Style';
      font-style: normal;
      font-weight: 400;
      src: url(https://fonts.gstatic.com/l/font?kit=MjQcmj92u_H-ux7vrpbgHLHRzMYMlfBjInIh27Te&skey=525c12327fbbcec7&v=v10) format('woff2');
      unicode-range: U+0370-03FF;
   }
   /* latin-ext */
   @font-face {
      font-family: 'Bookman Old Style';
      font-style: normal;
      font-weight: 400;
      src: url(https://fonts.gstatic.com/l/font?kit=MjQcmj92u_H-ux7vrpbgHLHRzMYMlfBuInIh27Te&skey=525c12327fbbcec7&v=v10) format('woff2');
      unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
   }
   /* latin */
   @font-face {
      font-family: 'Bookman Old Style';
      font-style: normal;
      font-weight: 400;
      src: url(https://fonts.gstatic.com/l/font?kit=MjQcmj92u_H-ux7vrpbgHLHRzMYMlfBgInIh27Te&skey=525c12327fbbcec7&v=v10) format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
   }

  .custom-font {
    font-family: 'Bookman Old Style', serif;
    font-size: 12pt;
    line-height: 1.1;
    font-weight: 400;
    color: black;
  }

  .table-naskah {
    white-space: normal !important;
  }

  p {
    margin-bottom: 0 !important;
    /* line-height: 1.6; */
  }

  li ol li {
    list-style-type: lower-alpha !important;
    
  }

  ol {
    padding-left: 22px !important;
    padding-top: 5px !important;
  }

  .instruksi > p:first-of-type {
    text-indent: 4em !important; /* Anda dapat menyesuaikan nilai ini sesuai keinginan */
    }
</style>
