<template>
    <div class="container">
        <form>
            <div class="form-group" style="display:none">
                <label for="document_type">Jenis Dokumen</label>
                <input type="text" disabled :class="['input', (!$v.form.document_type.required) ? 'is-invalid form-control' : 'form-control']" v-model="form.document_type" id="document_type" aria-describedby="document_type" placeholder="Pilih Jenis Dokumen">
                <small id="document_type" v-if="!$v.form.document_type.required"  class="form-text text-muted">Jenis Dokumen Wajid Diisi!</small>
            </div>
            <div class="form-group">
                <label for="document_type_id">Jenis Dokumen*</label>
                <v-select class="mb-3" disabled v-model="form.document_type_id" placeholder="Pilih Jenis Dokumen" :options="computedDocumentType" @input="handleDocumentTypeChange"></v-select>
                <small id="document_type" v-if="!$v.form.document_type_id.required"  class="form-text text-muted">Jenis Dokumen Wajid Diisi!</small>
            </div>

            <div class="form-group">
                <label for="document_type_id">Jenis Urusan*</label>
                <v-select class="mb-3" v-model="form.document_activity_id" placeholder="Pilih Jenis Urusan" :options="computedDocumentActivity"></v-select>
                <small id="document_type" v-if="!$v.form.document_activity_id.required"  class="form-text text-muted">Urusan Dokumen Wajid Diisi!</small>
            </div>
            <div class="form-group">
                <label for="title">Judul*</label>
                <!-- <textarea type="text" :class="['input', (!$v.form.title.required) ? 'is-invalid form-control' : 'form-control']" v-model="form.title" id="title" aria-describedby="title" placeholder="Ketik Judul" rows="3"></textarea> -->
                <vue-editor :editor-toolbar="customToolbar"
                            v-model="form.title" id="title" aria-describedby="title" placeholder="Ketik Judul"></vue-editor>
                <small id="title" v-if="!$v.form.title.required"  class="form-text text-muted">Judul Wajib Diisi!</small>
            </div>
            <div class="form-group" v-if="penomoran">
            <label for="title">Number Dokumen*</label>
            <input type="text" class="form-control" v-model="form.document_number" id="document_number" placeholder="Ketik Number Dokumen">
            <small id="document_number" class="form-text text-muted">Judul Wajib Diisi!</small>
        </div>

            <div class="form-group">
                <label for="proposer">Nama Pengusul*</label>
                <input type="text" :class="['input', (!$v.form.proposer.required) ? 'is-invalid form-control' : 'form-control']" v-model="form.proposer" id="proposer" aria-describedby="proposer" placeholder="Ketik Nama Pengusul">
                <small id="proposer" v-if="!$v.form.proposer.required"  class="form-text text-muted">Nama Pengusul Wajib Diisi!</small>
            </div>
            <div class="form-group">
                <label for="signer">No. Telepon / Whatsapp*</label>
                <input type="text" :class="['input', (!$v.form.signer.required) ? 'is-invalid form-control' : 'form-control']" v-model="form.signer" id="signer" aria-describedby="signer" placeholder="Ketik No. Telepon / Whatsapp">
                <small id="signer" v-if="!$v.form.signer.required"  class="form-text text-muted">No. Telepon / Whatsapp Wajib Diisi!</small>
            </div>
            <div class="form-group">
                <label for="unit">Bagian / Bidang / Sub*</label>
                <input type="text" :class="['input', (!$v.form.unit.required) ? 'is-invalid form-control' : 'form-control']" v-model="form.unit" id="unit" aria-describedby="unit" placeholder="Ketik Bagian / Bidan / Sub">
                <small id="unit" v-if="!$v.form.unit.required"  class="form-text text-muted">No. Telepon / Whatsapp Wajib Diisi!</small>
            </div>
            <div class="form-group">
                <label for="date">Tanggal Pengajuan*</label>
                <input type="date" :class="['input', (!$v.form.date.required) ? 'is-invalid form-control' : 'form-control']" v-model="form.date" id="date" aria-describedby="date" placeholder="Ketik Tanggal Pengajuan">
                <small id="date" v-if="!$v.form.date.required"  class="form-text text-muted">Tanggal Pengajuan Wajib Diisi!</small>
            </div>
            <div class="form-group" v-if="penomoran">
                <label for="date">Tanggal Penetapan*</label>
                <input type="date" class="form-control" v-model="form.determination_date" id="date" aria-describedby="date" placeholder="Ketik Tanggal Penetapan">
                <!-- <small id="date" class="form-text text-muted">Tanggal Penetapan Wajib Diisi!</small> -->
            </div>
        </form>
        <div class="row">
            <div class="col">
                <label for="document_supports">Upload Surat Pengantar & Dokumen Pendukung *</label>
                <div
                    class="card border d-flex justify-content-center text-center"
                    style="height: 150px"
                >
                    <div class="align-self-center mb-3">
                        <img src="@/assets/upload.png" width="100px" />
                    </div>
                    <button
                        class="btn btn-sm btn-primary align-self-center"
                        style="width: 15%"
                        @click="upload"
                        >
                        Upload Disini
                    </button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="div">
                    <input
                        hidden
                        type="file"
                        class="form-control"
                        ref="uploadField"
                        @change="selectFile"
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1" v-for="(item, index) in document_supports" :key="item.file">
            <CCardLink :href="item.file" target="_blank" class="">
                <img src="@/assets/attachment.png" width="100%" />
            </CCardLink>
            <button
                class="btn btn-danger btn-sm btn-block mt-2"
                @click="deleteFile(index)"
            >
                Hapus
            </button>
            <br />
            </div>
        </div>
    </div>
</template>

<script>
    import {validationMixin} from 'vuelidate'
    import { required } from 'vuelidate/lib/validators'
    import { uploadFile } from "@/utils/fileUpload";
    import { VueEditor } from "vue2-editor";
 
    export default {
        props: ['clickedNext', 'currentStep'],
        mixins: [validationMixin],
        data() {
            return {
                penomoran: false,
                qrcode: "",
                document_type: "",
                document_types: [],
                document_activities: [],
                params: {
                    sorttype: "desc",
                    sortby: "id",
                    row: 100,
                    page: 1,
                    keyword: "",
                },
                form: {
                    document_type: 'Keputusan Bupati',
                    document_type_id: '',
                    document_activity_id:'',
                    title: '',
                    document_number: '',
                    signer: '',
                    proposer: '',
                    unit: '',
                    date: '',
                    determination_date: '',
                    total_attachment: ''
                },
                document_supports: [],
                customToolbar: [
                    ["bold", "italic", "underline"],
                ],
            }
        },
        components: {
            VueEditor
        },
        methods: {
            handleDocumentTypeChange() {
                // This method will be triggered when the v-select value changes
                // Do something with the changed value, e.g., make an API call, update other fields, etc.
                console.log('Selected Document Type:', this.form.document_type_id.label);
                this.$emit('typeSelected', this.form.document_type_id.label);
            },
            deleteFile(index) {
                this.document_supports.splice(index, 1);
            },
            getDataDocumentType() {
                var loading = this.$loading.show();
                this.$store
                    .dispatch("document_type/get", this.params)
                    .then((resp) => {
                    this.document_types = resp.data.data;
                    const available = this.document_types.find(item => {
                        return item.name == this.document_type;
                    })
                    if (available) {
                        this.form.document_type_id = available.id;
                    }
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error("gagal mengambil data  \n", e);
                    loading.hide();
                });
            },
            getDataDocumentActivity() {
                var loading = this.$loading.show();
                this.$store
                    .dispatch("activity/get", this.params)
                    .then((resp) => {
                    this.document_activities = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error("gagal mengambil data  \n", e);
                    loading.hide();
                });
            },
            upload() {
                this.$refs.uploadField.click();
            },
            selectFile(event) {
                this.file = event.target.files[0];
                var loading = this.$loading.show();
                uploadFile(this.file)
                    .then((resp) => {
                        this.document_supports.push({file: resp});
                        loading.hide();
                        this.$swal.fire({
                            icon: 'success',
                            text: 'File berhasil diupload !!',
                        });
                    })
                    .catch((e) => {
                        loading.hide();
                        this.$swal.fire({
                            icon: 'warning',
                            text: 'Terjadi kesalahan, ' + e,
                        })
                    });
            },
            generateString() {
                const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let result = '';
                const charactersLength = characters.length;
                for ( let i = 0; i < 10; i++ ) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            }
        },
        validations: {
            form: {
                document_type: {
                    required
                },
                document_type_id: {
                    required
                },
                document_activity_id: {
                    required
                },
                title: {
                    required
                },
                // document_number: {
                //     required
                // },
                signer: {
                    required
                },
                proposer: {
                    required
                },
                unit: {
                    required
                },
                date: {
                    required
                }
                // determination_date: {
                //     required
                // }
            },
            document_supports: {
                required
            }
        },
        watch: {
            $v: {
                handler: function (val) {
                    if(!val.$invalid) {
                        this.form.penomoran = this.penomoran;
                        if (!this.form.qrcode) {
                            this.form.qrcode = process.env.VUE_APP_API_URL + 'document/qrcode/' + this.generateString();
                        }
                        this.form.document_supports = this.document_supports;
                        this.$store.commit('document/setDocument', this.form);
                        this.$emit('can-continue', {value: true});
                    } else {
                        this.$emit('can-continue', {value: false});
                    }
                },
                deep: true
            },
            currentStep(val) {
                if(!this.$v.invalid) {
                    this.$emit('can-continue', {value: true});
                } else {
                    this.$emit('can-continue', {value: false});
                }
            }
        },
        mounted() {
            let document = this.$store.state.document.document;
            this.document_type = this.$store.state.document.document_type;
            this.penomoran = this.$store.state.document?.document.penomoran;
            this.form.id = document.id ? document.id : '';
            this.form.title = document.title ? document.title : '';
            this.form.date = document.date ? this.$moment(new Date(document.date)).format("YYYY-MM-DD")  : '';
            this.form.determination_date = document.determination_date ? this.$moment(new Date(document.determination_date)).format("YYYY-MM-DD")  : '';
            this.form.signer = document.signer ? document.signer : '';
            this.form.proposer = document.proposer ? document.proposer : '';
            this.form.unit = document.unit ? document.unit : '';
            this.form.document_number = document.document_number ? document.document_number : '';
            this.form.document_type_id = document.document_type_id ? document.document_type_id : '';
            this.form.document_activity_id = document.document_activity_id ? document.document_activity_id : '';
            this.form.total_attachment = document.total_attachment ? document.total_attachment : '';
            this.form.draft_sk = document.draft_sk ? document.draft_sk : '';
            this.form.qrcode = document.qrcode ? document.qrcode : '';
            this.document_supports = this.$store.state.document.document_supports;

            if(!this.$v.$invalid) {
                this.$emit('can-continue', {value: true});
            } else {
                this.$emit('can-continue', {value: false});
            }

            this.getDataDocumentType();
            this.getDataDocumentActivity();
        },
        computed: {
            computedDocumentType() {
                if (this.form.document_type_id) {
                    let doc = this.document_types.find(element => {
                            return element.id == this.form.document_type_id
                        });
                        if (doc) {
                            let document_type = {
                            value: this.form.document_type_id,
                            label: doc.name
                        }
                        this.form.document_type_id = document_type
                    }
                }
                return this.document_types.map((item) => {
                    return {
                        value: item.id,
                        label: item.name,
                    };
                });
            },
            computedDocumentActivity() {
                if (this.form.document_activity_id) {
                    let doc = this.document_activities.find(element => {
                            return element.id == this.form.document_activity_id
                        });
                        if (doc) {
                            let document_activities = {
                            value: this.form.document_activity_id,
                            label: doc.description
                        }
                        this.form.document_activity_id = document_activities
                    }
                }
                return this.document_activities.map((item) => {
                    return {
                        value: item.id,
                        label: item.description,
                    };
                });
            },
        },
    }
</script>