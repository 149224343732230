<template>
  <div class="container">
    <CCard>
        <CCardHeader color="primary" style="color:white;">Detail Informasi Pengajuan Draft Produk Hukum</CCardHeader>
        <CCardBody>
            <div class="row">
                <div class="col-lg-6">
                    <CCard>
                        <table class="table" style="white-space: normal !important;">
                            <tr>
                                <td style="width: 150px">Judul</td>
                                <td style="width: 10px">:</td>
                                <td><div v-html="document.tittle"></div></td>
                            </tr>
                            <tr>
                                <td>Jenis Dokumen</td>
                                <td>:</td>
                                <td>{{ document.document_type.name }}</td>
                            </tr>
                            <tr v-if="document.draft_sk && user.verificator == 0 && isShow(document)">
                                <td>Nomor Dokumen</td>
                                <td>:</td>
                                <td>{{ document.document_number }}</td>
                            </tr>
                            <tr v-else-if="user.verificator !== 0">
                                <td>Nomor Dokumen</td>
                                <td>:</td>
                                <td>{{ document.document_number }}</td>
                            </tr>
                            <tr>
                                <td>Perangkat Daerah</td>
                                <td>:</td>
                                <td>{{ document.user ? document.user.regional_apparatus_name : '-' }}</td>
                            </tr>
                            <tr>
                                <td>Penyusun</td>
                                <td>:</td>
                                <td>{{ document.proposer }}</td>
                            </tr>
                            <tr>
                                <td>No. Whatsapp / Telephone</td>
                                <td>:</td>
                                <td>{{ document.signer }}</td>
                            </tr>
                            <tr>
                                <td>Bagian / Bidang / Sub</td>
                                <td>:</td>
                                <td>{{ document.unit }}</td>
                            </tr>
                            <tr>
                                <td>Legal Drafter</td>
                                <td>:</td>
                                <td><b>{{ document.legal_drafter ? document.legal_drafter.full_name : '-' }}</b></td>
                            </tr>
                            <tr>
                                <td>Nomor HP Legal Drafter</td>
                                <td>:</td>
                                <td><b>{{ document.legal_drafter ? document.legal_drafter.phone_number : '-' }}</b></td>
                            </tr>
                            <tr>
                                <td>Tanggal Pengajuan</td>
                                <td>:</td>
                                <td>{{ this.$moment(document.date).format("Do MMMM YYYY") }}</td>
                            </tr>
                            <tr v-if="document.draft_sk && user.verificator == 0 && isShow(document)">
                                <td>Tanggal Penetapan</td>
                                <td>:</td>
                                <td>
                                    {{ 
                                            document.document_number !== '-'
                                            ? this.$moment(document.determination_date).format("Do MMMM YYYY") 
                                            : 'Belum Ditetapkan' 
                                    }}
                                </td>
                            </tr>
                            <tr v-else-if="user.verificator !== 0">
                                <td>Tanggal Penetapan</td>
                                <td>:</td>
                                <td>
                                    {{ 
                                            document.document_number !== '-'
                                            ? this.$moment(document.determination_date).format("Do MMMM YYYY") 
                                            : 'Belum Ditetapkan' 
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td>Tanggal Dibuat</td>
                                <td>:</td>
                                <td>{{ this.$moment(document.created_at).format("Do MMMM YYYY HH:mm") }}</td>
                            </tr>
                            <tr>
                                <td>Tanggal Diupdate</td>
                                <td>:</td>
                                <td>{{ this.$moment(document.updated_at).format("Do MMMM YYYY HH:mm") }}</td>
                            </tr>
                            <tr>
                                <td>Status</td>
                                <td>:</td>
                                <td>
                                    <span v-if="document.status == 'PROSES' || document.status == 'DIEDIT'" class="badge bg-warning" style="color:white">{{ document.status }}</span>
                                    <span v-else-if="document.status == 'PENGAJUAN'" class="badge bg-dark" style="color:white">{{ document.status }}</span>
                                    <span v-else-if="document.status == 'DIKEMBALIKAN'" class="badge bg-danger" style="color:white">{{ document.status }}</span>
                                    <span v-else-if="document.status == 'LEGAL DRAFTING'" class="badge bg-info" style="color:white">{{ document.status }}</span>
                                    <span v-else-if="document.status == 'REVISI'" class="badge bg-danger" style="color:white">{{ document.status }}</span>
                                    <span v-else class="badge bg-primary" style="color:white">{{ 
                                         user.verificator == 0 ? document.document_type.name == 'KEPUTUSAN SEKDA' ? document.status == 'APPROVED SEKDA' ? 'SELESAI' : document.status : document.status : document.status
                                    }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td style="vertical-align: middle;">Draft Awal</td>
                                <td style="vertical-align: middle;">:</td>
                                <td style="vertical-align: middle;">
                                    <a v-if="document.first_draft_sk" target="_blank" :href="document.first_draft_sk" class="m-1 btn btn-success btn-sm" >
                                        Download File
                                    </a>
                                </td>
                            </tr>
                        </table>
                    </CCard>
                    <CCard>
                        <CCardHeader color="primary" style="color:white;">Informasi Verifikator</CCardHeader>
                        <table class="table" style="white-space: normal !important;">
                            <tr>
                                <td>Admin </td>
                                <td>:</td>
                                <td>{{ document.admin_verified ? document.admin_verified.full_name : '-' }}</td>
                            </tr>
                            <tr>
                                <td>Tanggal Admin Approved</td>
                                <td>:</td>
                                <td>{{ document.admin_verified_at ? this.$moment(document.admin_verified_at).format("Do MMMM YYYY") : '-' }}</td>
                            </tr>
                            <tr>
                                <td>Legal Drafter </td>
                                <td>:</td>
                                <td>{{ document.legal_drafter ? document.legal_drafter.full_name : '-' }}</td>
                            </tr>
                            <tr>
                                <td>Tanggal Legal Drafter Approved</td>
                                <td>:</td>
                                <td>{{ document.legal_drafter_verified_at ? this.$moment(document.legal_drafter_verified_at).format("Do MMMM YYYY") : '-' }}</td>
                            </tr>
                            <tr>
                                <td>Suncang </td>
                                <td>:</td>
                                <td>{{ document.suncang_verified ? document.suncang_verified.full_name : '-' }}</td>
                            </tr>
                            <tr>
                                <td>Tanggal Suncang Approved</td>
                                <td>:</td>
                                <td>{{ document.suncang_verified_at ? this.$moment(document.suncang_verified_at).format("Do MMMM YYYY") : '-' }}</td>
                            </tr>
                            <tr>
                                <td>Analis Hukum </td>
                                <td>:</td>
                                <td>{{ document.kasubag_verified ? document.kasubag_verified.full_name : '-' }}</td>
                            </tr>
                            <tr>
                                <td>Tanggal Analis Hukum Approved</td>
                                <td>:</td>
                                <td>{{ document.kasubag_verified_at ? this.$moment(document.kasubag_verified_at).format("Do MMMM YYYY") : '-' }}</td>
                            </tr>
                            <tr>
                                <td>{{ this.$moment(document.created_at).diff(this.$moment('2024-09-23')) >= 0 ? 'Plt. Kabag' : 'Kabag' }}</td>
                                <td>:</td>
                                <td>{{ document.kabag_verified ? document.kabag_verified.full_name : '-' }}</td>
                            </tr>
                            <tr>
                                <td>Tanggal Kabag Approved</td>
                                <td>:</td>
                                <td>{{ document.kabag_verified_at ? this.$moment(document.kabag_verified_at).format("Do MMMM YYYY") : '-' }}</td>
                            </tr>
                            <tr v-if="document.asistant_verified">
                                <td>Asisten </td>
                                <td>:</td>
                                <td>{{ document.asistant_verified ? document.asistant_verified.full_name : '-' }}</td>
                            </tr>
                            <tr v-if="document.asistant_verified">
                                <td>Tanggal Asisten Approved</td>
                                <td>:</td>
                                <td>{{ document.asistant_verified_at ? this.$moment(document.asistant_verified_at).format("Do MMMM YYYY") : '-' }}</td>
                            </tr>
                            <tr>
                                <td>Sekda </td>
                                <td>:</td>
                                <td>{{ document.sekda_verified ? document.sekda_verified.full_name : '-' }}</td>
                            </tr>
                            <tr>
                                <td>Tanggal Sekda Approved</td>
                                <td>:</td>
                                <td>{{ document.sekda_verified_at ? this.$moment(document.sekda_verified_at).format("Do MMMM YYYY") : '-' }}</td>
                            </tr>
                            <tr v-if="document.document_type.name == 'KEPUTUSAN BUPATI' || document.document_type.name == 'INSTRUKSI BUPATI'">
                                <td>{{ this.$moment(document.determination_date).isBetween(this.$moment('2024-9-26'), this.$moment('2024-11-25'), 'days') ? 'Pjs. Bupati' : 'Bupati' }}</td>
                                <td>:</td>
                                <!-- <td>{{ document.bupati_verified ? document.bupati_verified.full_name : '-' }}</td> -->
                                <td>{{ this.$moment(document.determination_date).isBetween(this.$moment('2024-9-26'), this.$moment('2024-11-25'), 'days') ? 'DIKKY ACHMAD SIDIK,ST.,MT.' : 'Dr. H. M. Dadang Supriatna, S.Ip., M.Si.' }}</td>
                            </tr>
                            <tr v-if="document.document_type.name == 'KEPUTUSAN BUPATI' || document.document_type.name == 'INSTRUKSI BUPATI'">
                                <td>Tanggal Bupati Approved</td>
                                <td>:</td>
                                <td>{{ document.bupati_verified_at ? this.$moment(document.bupati_verified_at).format("Do MMMM YYYY") : '-' }}</td>
                            </tr>
                        </table>
                    </CCard>
                </div>
                <div class="col-lg-6">
                   
                    <!-- tambah kondisi pdf muncul jika sudah di verifikasi oleh kabag -->
                    <div v-if="document.draft_sk && user.verificator == 0 && isShow(document) && !sk_sakti.includes(document.id)">
                    
                        <!-- <iframe ref="myIframe" :src="document.draft_sk + isDownload()" width="100%" height="600" type="application/pdf" id="draf_pdf" @load="handleIframeLoad"></iframe> -->
                        <br /><br />
                    </div>
                    <div v-else-if="user.verificator !== 0 && !sk_sakti.includes(document.id)">
                        <iframe ref="myIframe" :src="document.draft_sk + isDownload()" width="100%" height="600" type="application/pdf" id="draf_pdf" @load="handleIframeLoad"></iframe>
                        <br /><br />
                    </div>
                    <div v-else-if="user.verificator == 1 && sk_sakti.includes(document.id)">
                        <iframe ref="myIframe" :src="document.draft_sk + isDownload()" width="100%" height="600" type="application/pdf" id="draf_pdf" @load="handleIframeLoad"></iframe>
                        <br /><br />
                    </div>
                    <div v-else-if="user.verificator == 7">
                        <iframe ref="myIframe" :src="document.draft_sk + isDownload()" width="100%" height="600" type="application/pdf" id="draf_pdf" @load="handleIframeLoad"></iframe>
                        <br /><br />
                    </div>
                    <CCard>
                        <CCardHeader color="primary" style="color:white;">File Lampiran Dokumen</CCardHeader>
                        <div class="table-responsive">
                            <table class="table">
                                <tr>
                                    <th style="width: 10px">No.</th>
                                    <th>Versi</th>
                                    <th>File</th>
                                    
                                </tr>
                                <tr v-for="(_status, index) in document.document_attachment" :key="_status.id">
                                    <template v-if="_status.is_file">
                                        <td style="width: 10px">{{ index +1 }}.</td>
                                        <td>{{ _status.tittle }}</td>
                                        <td>
                                            <a :href="_status.description" class="m-1 btn btn-success btn-sm" >
                                                Download File
                                            </a>
                                        </td>
                                        
                                    </template>
                                </tr>
                            </table>
                        </div>
                    </CCard>
                    <CCard>
                        <CCardHeader color="primary" style="color:white;">Surat Pengantar & Dokumen Pendukung</CCardHeader>
                        <div class="table-responsive">
                            <table class="table">
                                <tr>
                                    <th style="width: 10px">No.</th>
                                    <th style="width: 100px">File</th>
                                    <th>Tanggal</th>
                                </tr>
                                <tr v-for="(_status, index) in document.document_support" :key="_status.id">
                                    <td style="width: 10px">{{ index +1 }}.</td>
                                    <td>
                                        <a :href="_status.file" class="m-1 btn btn-success btn-sm" >
                                            Download File
                                        </a>
                                    </td>
                                    <td>{{ format_date(_status.created_at) }}</td>
                                </tr>
                            </table>
                        </div>
                    </CCard>
                    <CCard>
                        <CCardHeader color="primary" style="color:white;">Status Riwayat Dokumen</CCardHeader>
                        <div class="table-responsive">
                            <table class="table">
                                <tr>
                                    <th style="width: 10px">No.</th>
                                    <th>Status</th>
                                    <th>Catatan</th>
                                    <th>Pencatat</th>
                                    <th>Tanggal</th>
                                    <th>Durasi</th>
                                </tr>
                                <template v-if="user.verificator == 0">
                                    <tr v-for="(_status, index) in document.document_status" :key="_status.id">
                                        <template v-if="_status.status == 'DIKEMBALIKAN'">
                                            <td style="width: 10px; vertical-align:middle">{{ index +1 }}.</td>
                                            <td style="vertical-align:middle">
                                                <span v-if="_status.status == 'PROSES' || _status.status == 'DIEDIT'" class="badge bg-warning" style="color:white">{{ _status.status }}</span>
                                                <span v-else-if="_status.status == 'PENGAJUAN'" class="badge bg-dark" style="color:white">{{ _status.status }}</span>
                                                <span v-else-if="_status.status == 'DIKEMBALIKAN'" class="badge bg-danger" style="color:white">{{ _status.status }}</span>
                                                <span v-else-if="_status.status == 'LEGAL DRAFTING'" class="badge bg-info" style="color:white">{{ _status.status }}</span>
                                                <span v-else-if="_status.status == 'REVISI'" class="badge bg-danger" style="color:white">{{ _status.status }}</span>
                                                <span v-else class="badge bg-primary" style="color:white">{{ _status.status }}</span>
                                            </td>
                                            <td style="vertical-align:middle">
                                                <template v-if="_status.status === 'REVISI' || _status.status === 'KOMENTAR' ||  _status.status === 'DIKEMBALIKAN'">
                                                    <CButton class="m-2 btn btn-success btn-sm" @click="revisi(_status)">Lihat Catatan </CButton>
                                                </template>
                                                <template v-else>
                                                    {{ _status.remark }}
                                                </template>
                                            </td>
                                            <td style="vertical-align:middle">{{ _status.users.full_name }}</td>
                                            <td style="vertical-align:middle">{{ format_date(_status.created_at) }}</td>
                                            <td style="vertical-align:middle">{{_status.durasi }}</td>
                                        </template>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr v-for="(_status, index) in document.document_status" :key="_status.id">
                                        <!-- v-if="(user.verificator == 2 || user.verificator == 1)" -->
                                        <template>
                                            <td style="width: 10px; vertical-align:middle">{{ index +1 }}.</td>
                                                <td style="vertical-align:middle">
                                                    <span v-if="_status.status == 'PROSES' || _status.status == 'DIEDIT'" class="badge bg-warning" style="color:white">{{ _status.status }}</span>
                                                    <span v-else-if="_status.status == 'PENGAJUAN'" class="badge bg-dark" style="color:white">{{ _status.status }}</span>
                                                    <span v-else-if="_status.status == 'DIKEMBALIKAN'" class="badge bg-danger" style="color:white">{{ _status.status }}</span>
                                                    <span v-else-if="_status.status == 'LEGAL DRAFTING' || _status.status == 'PENOMORAN BELUM MERGE'" class="badge bg-info" style="color:white">{{ _status.status }}</span>
                                                    <span v-else-if="_status.status == 'REVISI'" class="badge bg-danger" style="color:white">{{ _status.status }}</span>
                                                    <span v-else class="badge bg-primary" style="color:white">{{ _status.status }}</span>
                                                </td>
                                                <td style="vertical-align:middle">
                                                    <template v-if="_status.status === 'REVISI' || _status.status === 'KOMENTAR' ||  _status.status === 'DIKEMBALIKAN'">
                                                        <CButton class="m-2 btn btn-success btn-sm" @click="revisi(_status)">Lihat Catatan </CButton>
                                                    </template>
                                                    <template v-else>
                                                        {{ _status.remark }}
                                                    </template>
                                                </td>
                                                <td style="vertical-align:middle">{{ _status.users.full_name }}</td>
                                                <td style="vertical-align:middle">{{ format_date(_status.created_at) }}</td>
                                                <td style="vertical-align:middle">{{_status.durasi }}</td>
                                        </template>
                                        <!--
                                           <template v-if="_status.status !== 'KOMENTAR'">
                                                <td style="width: 10px; vertical-align:middle">{{ index +1 }}.</td>
                                                <td style="vertical-align:middle">
                                                    <span v-if="_status.status == 'PROSES' || _status.status == 'DIEDIT'" class="badge bg-warning" style="color:white">{{ _status.status }}</span>
                                                    <span v-else-if="_status.status == 'PENGAJUAN'" class="badge bg-dark" style="color:white">{{ _status.status }}</span>
                                                    <span v-else-if="_status.status == 'DIKEMBALIKAN'" class="badge bg-danger" style="color:white">{{ _status.status }}</span>
                                                    <span v-else-if="_status.status == 'LEGAL DRAFTING' || _status.status == 'PENOMORAN BELUM MERGE'" class="badge bg-info" style="color:white">{{ _status.status }}</span>
                                                    <span v-else-if="_status.status == 'REVISI'" class="badge bg-danger" style="color:white">{{ _status.status }}</span>
                                                    <span v-else class="badge bg-primary" style="color:white">{{ _status.status }}</span>
                                                </td>
                                                <td style="vertical-align:middle">
                                                    <template v-if="_status.status === 'REVISI' || _status.status === 'KOMENTAR' ||  _status.status === 'DIKEMBALIKAN'">
                                                        <CButton class="m-2 btn btn-success btn-sm" @click="revisi(_status)">Lihat Catatan </CButton>
                                                    </template>
                                                    <template v-else>
                                                        {{ _status.remark }}
                                                    </template>
                                                </td>
                                                <td style="vertical-align:middle">{{ _status.users.full_name }}</td>
                                                <td style="vertical-align:middle">{{ format_date(_status.created_at) }}</td>
                                                <td style="vertical-align:middle">{{_status.durasi }}</td>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <td style="width: 10px; vertical-align:middle">{{ index +1 }}.</td>
                                            <td style="vertical-align:middle">
                                                <span v-if="_status.status == 'PROSES' || _status.status == 'DIEDIT'" class="badge bg-warning" style="color:white">{{ _status.status }}</span>
                                                <span v-else-if="_status.status == 'PENGAJUAN'" class="badge bg-dark" style="color:white">{{ _status.status }}</span>
                                                <span v-else-if="_status.status == 'DIKEMBALIKAN'" class="badge bg-danger" style="color:white">{{ _status.status }}</span>
                                                <span v-else-if="_status.status == 'LEGAL DRAFTING' || _status.status == 'PENOMORAN BELUM MERGE'" class="badge bg-info" style="color:white">{{ _status.status }}</span>
                                                <span v-else-if="_status.status == 'REVISI'" class="badge bg-danger" style="color:white">{{ _status.status }}</span>
                                                <span v-else class="badge bg-primary" style="color:white">{{ _status.status }}</span>
                                            </td>
                                            <td style="vertical-align:middle">
                                                <template v-if="_status.status === 'REVISI' || _status.status === 'KOMENTAR' ||  _status.status === 'DIKEMBALIKAN'">
                                                    <CButton class="m-2 btn btn-success btn-sm" @click="revisi(_status)">Lihat Catatan </CButton>
                                                </template>
                                                <template v-else>
                                                    {{ _status.remark }}
                                                </template>
                                            </td>
                                            <td style="vertical-align:middle">{{ _status.users.full_name }}</td>
                                            <td style="vertical-align:middle">{{ format_date(_status.created_at) }}</td>
                                            <td style="vertical-align:middle">{{_status.durasi }}</td>
                                        </template> -->
                                    </tr>
                                </template>
                            </table>
                        </div>
                    </CCard>

                    <div v-if="user.verificator == 0 && isShow(document) && !sk_sakti.includes(document.id)">
                        <CCard>
                            <CCardHeader color="primary" style="color:white;">QRCODE</CCardHeader>
                            <qr-code class="p-4 text-center" :text="document.qrcode"></qr-code>
                        </CCard>
                    </div>
                    <div v-else-if="user.verificator !== 0 && !sk_sakti.includes(document.id)">
                        <CCard>
                            <CCardHeader color="primary" style="color:white;">QRCODE</CCardHeader>
                            <qr-code class="p-4 text-center" :text="document.qrcode"></qr-code>
                        </CCard>
                    </div>
                    <div v-else-if="user.verificator == 1 && sk_sakti.includes(document.id)">
                        <CCard>
                            <CCardHeader color="primary" style="color:white;">QRCODE</CCardHeader>
                            <qr-code class="p-4 text-center" :text="document.qrcode"></qr-code>
                        </CCard>
                    </div>
                    
                </div>
            </div>
        </CCardBody>
        
        <CCardFooter v-if="user.verificator === 0" >
            <CButton class="m-1 btn btn-success" @click="edit() " v-if="document.status == 'DIKEMBALIKAN'"
                >
                Edit Dokumen
            </CButton>
            <CButton class="m-1 btn btn-primary" v-if="!document.admin_verified && (document.status == 'DIEDIT' || document.status == 'DIKEMBALIKAN')" @click="rePropose()" 
                >
                Ajukan Kembali
            </CButton>
        </CCardFooter>

        <CCardFooter v-if="user.verificator === 1" >
            <button
                class="btn btn-sm btn-primary ml-2" v-if="document.status == 'PENOMORAN BELUM MERGE'"
                @click="addModal()"
              >
                
                Merge Dokument Draft
              </button>
              <button v-if="document.status == 'APPROVED KABAG'"
                class="btn btn-sm btn-primary ml-2"
                @click="penomoran()"
              >
                Penomoran
              </button>
              <button v-if="(document.document_type.name == 'INSTRUKSI BUPATI' && document.status == 'APPROVED SEKDA')  || (document.document_type.name == 'KEPUTUSAN BUPATI' && document.status == 'APPROVED SEKDA') || (document.document_type.name == 'KEPUTUSAN SEKDA' && document.status == 'APPROVED ASISTANT')"
                class="btn btn-sm btn-primary ml-2"
                @click="uploadEoffice()"
              >
                Upload Ke E-Office
              </button>
            <CButton v-if="(document.status !== 'PENOMORAN BELUM MERGE' && document.status !== 'PENOMORAN' && document.status !== 'APPROVED KABAG') && document.kabag_verified"
                class="m-1 btn btn-danger btn-sm" 
                @click="rollbackPenomoran()"
                    >
                    Rollback Penomoran
            </CButton>
            <CButton  v-if="document.status == 'PENOMORAN BELUM MERGE'"
                class="m-1 btn btn-danger btn-sm" 
                @click="rollbackMergePenomoran()"
                    >
                    Rollback Merge Penomoran
            </CButton>
        </CCardFooter>
        
        <CCardFooter v-if="(user.verificator === 1 && !document.admin_verified && document.status == 'PENGAJUAN') || user.verificator === 1 && !document.admin_verified && document.status == 'DIEDIT'">
            <CButton v-if="document.document_type.name == 'INSTRUKSI BUPATI'"
                class="m-1 btn btn-primary"
                @click="approve(38)" 
                >
                Acc Intan
            </CButton>
            <CButton v-if="document.document_type.name == 'INSTRUKSI BUPATI'"
                class="m-1 btn btn-primary"
                @click="approve(39)" 
                >
                Acc Quyna
            </CButton>
            <CButton v-if="document.document_type.name == 'INSTRUKSI BUPATI'"
                class="m-1 btn btn-primary"
                @click="approve(45)" 
                >
                Acc Eka 
            </CButton>
            <CButton v-if="document.document_type.name !== 'INSTRUKSI BUPATI'"
                class="m-1 btn btn-primary"
                @click="approve(40)" 
                >
                Acc Ramadhan
            </CButton>
            <CButton v-if="document.document_type.name !== 'INSTRUKSI BUPATI'"
                class="m-1 btn btn-primary"
                @click="approve(41)" 
                >
                Acc Hilwa
            </CButton>
            <CButton v-if="document.document_type.name !== 'INSTRUKSI BUPATI'"
                class="m-1 btn btn-primary"
                @click="approve(42)" 
                >
                Acc Fikri
            </CButton>
            <CButton v-if="document.document_type.name !== 'INSTRUKSI BUPATI'"
                class="m-1 btn btn-primary"
                @click="approve(43)" 
                >
                Acc Ahlul
            </CButton>
            <CButton v-if="document.document_type.name !== 'INSTRUKSI BUPATI'"
                class="m-1 btn btn-primary"
                @click="approve(44)" 
                >
                Acc Rizky
            </CButton>
            <CButton v-if="document.document_type.name !== 'INSTRUKSI BUPATI'"
                class="m-1 btn btn-primary"
                @click="approve(46)" 
                >
                Acc Adi
            </CButton>
            <CButton v-if="document.document_type.name !== 'INSTRUKSI BUPATI'"
                class="m-1 btn btn-primary"
                @click="approve(56)" 
                >
                Acc Zaenal
            </CButton>
            <!-- <CButton v-if="document.document_type.name !== 'INSTRUKSI BUPATI'"
                class="m-1 btn btn-primary"
                @click="approve(139)" 
                >
                Acc Ramos
            </CButton> -->
            <CButton v-if="document.document_type.name !== 'INSTRUKSI BUPATI'"
                class="m-1 btn btn-primary"
                @click="approve(140)" 
                >
                Acc Galih
            </CButton>
            <CButton 
                class="m-1 btn btn-primary"
                @click="approve()" 
                >
                Acc Random
            </CButton>
            
            <CButton 
                class="m-1 btn btn-warning" 
                @click="return_doc()"
                >
                Dikembalikan
            </CButton>
  
        </CCardFooter>

        <CCardFooter v-else-if="user.verificator === 2 && document.admin_verified &&  (document.status == 'PROSES' || document.status == 'DIEDIT' || document.status == 'REVISI')">
            <CButton 
                class="m-1 btn btn-primary" 
                @click="approve()"
                    >
                Approve Legal Drafter
            </CButton>
            <CButton class="m-1 btn btn-success" @click="edit()"
                >
                Edit Dokumen
            </CButton>
            <!-- <CButton 
            class="m-1 btn btn-danger" 
            @click="return_doc()"
                >
                Revisi
            </CButton> -->
            <CButton 
                class="m-1 btn btn-warning ml-2" 
                @click="return_doc()"
                >
                Dikembalikan Ke OPD
            </CButton>
            <button
                class="btn btn-primary ml-2"
                @click="addModal()"
              >
                
                Merge Dokument Draft
            </button>
        </CCardFooter>

        <CCardFooter v-else-if="user.verificator === 3 && document.status == 'LEGAL DRAFTING'">
            <CButton 
                class="m-1 btn btn-primary" 
                @click="approve()"
                    >
                    Approve Suncang
            </CButton>
             <CButton 
                class="m-1 btn btn-danger" 
                @click="return_doc()"
                    >
                    Revisi
            </CButton>
        </CCardFooter>

        <CCardFooter v-else-if="user.verificator === 4  && document.status == 'APPROVED SUNCANG'">
            <CButton 
                class="m-1 btn btn-primary" 
                @click="approve()"
                    >
                Approve Analis Hukum
            </CButton>
            <CButton 
            class="m-1 btn btn-danger" 
            @click="return_doc()"
                >
                Revisi
            </CButton>
        </CCardFooter>

        <CCardFooter v-else-if="user.verificator === 5  && document.status == 'APPROVED ANALIS HUKUM'">
            <CButton 
                class="m-1 btn btn-primary" 
                @click="approve()"
                    >
                Approve Kabag
            </CButton>
            <CButton 
            class="m-1 btn btn-danger" 
            @click="return_doc()"
                >
                Revisi
            </CButton>
        </CCardFooter>

        <!-- proses tte ASPEM -->
        <CCardFooter v-else-if="user.verificator === 5  && document.status == 'PENOMORAN'">
            <CButton 
                class="m-1 btn btn-primary" 
                @click="tteKabag()"
                    >
                TTE ASPEM
            </CButton>
        </CCardFooter>

        <CCardFooter v-else-if="user.verificator === 6  && document.status == 'TTE ASPEM'">
            <CButton 
                class="m-1 btn btn-primary" 
                @click="approve()"
                    >
                Approve Asisten
            </CButton>
            <CButton 
                class="m-1 btn btn-danger" 
                @click="return_doc()"
                    >
                    Revisi
            </CButton>
        </CCardFooter>

        <CCardFooter v-else-if="(user.verificator === 7  && document.status == 'APPROVED ASSISTANT') || (user.verificator == 7 && !document.asistant_verified && document.status == 'TTE ASPEM')">
            <CButton 
                class="m-1 btn btn-primary"
                @click="approve()" 
                    >
                Approve Sekda
            </CButton>
            <CButton 
                class="m-1 btn btn-danger" 
                @click="return_doc()"
                    >
                    Revisi
            </CButton>
        </CCardFooter>

        <CCardFooter v-else-if="user.verificator === 8  && (document.status == 'UPLOAD KE E-OFFICE' || document.status == 'APPROVED SEKDA')">
            <CButton 
                class="m-1 btn btn-primary"
                @click="approve()" 
                    >
                Approve Bupati
            </CButton>
            <CButton 
                class="m-1 btn btn-danger" 
                @click="return_doc()"
                    >
                    Revisi
            </CButton>
        </CCardFooter>

        <!-- untuk komentar -->
        <!-- <CCardFooter v-if="user.verificator === 8 || 
            user.verificator === 7 || 
            user.verificator === 6 || 
            user.verificator === 5 ||
            user.verificator === 4 || user.verificator === 3">

            <CButton 
                class="m-1 btn-sm btn btn-success" @click="komentar()"
                    >
                    Komentar
            </CButton>
        </CCardFooter> -->
        <!-- revisi semua akun bisa berkomentar -->
        <CCardFooter>
            <CButton 
                class="m-1 btn-sm btn btn-success" @click="komentar()"
                    >
                    Komentar
            </CButton>
        </CCardFooter>

    </CCard>
    <CModal
      size="lg"
      title="Upload File"
      :closeOnBackdrop="false"
      centered
      :color="isUpdate ? 'success' : 'primary'"
      :show.sync="createModal"
    >
      <div class="row">
        <div class="col-12">
            <div
                class="card border d-flex justify-content-center text-center"
                style="height: 150px"
            >
                <div class="align-self-center mb-3">
                    <img src="@/assets/upload.png" width="100px" />
                </div>
                <button
                    class="btn btn-sm btn-primary align-self-center"
                    style="width: 15%"
                    @click="upload"
                    >
                    Upload Disini
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="div">
                    <input
                        hidden
                        type="file"
                        class="form-control"
                        ref="uploadField"
                        @change="selectFile"
                        accept="application/pdf"
                    />
                </div>
            </div>
        </div>
      </div>
      <div class="row" v-if="draft_sk !== ''">
            <div class="col-md-2" >
                <CCardLink :href="draft_sk" target="_blank" class="">
                    <img src="@/assets/attachment.png" width="100%" />
                </CCardLink>
                <br />
                <a :href="draft_sk" target="_blank" style="width: 100%;"
                    class="btn btn-sm btn-secondary align-self-center"
                    >
                    Download
                </a>
                <br />
            </div>
        </div>
      <template slot="footer">
        <div>
          <button @click="merge" v-if="!isUpdate" class="btn btn-primary">
            Merge
          </button>
        </div>
      </template>
    </CModal>
    <CModal
      size="lg"
      title="Catatan Revisi"
      :closeOnBackdrop="false"
      centered
      :color="'primary'"
      :show.sync="createModalRevisi"
    >
        <div v-html="document.tittle"></div>
        <div class="row">
            <div class="col-12">
            <textarea
                class="form-control"
                v-model="catatan"
                placeholder="ketik catatan revisi disini*"
                rows="4"
            ></textarea>    
            </div>
        </div>
        <br />
        <p>Upload Dokumen Pendukung Jika Ada :</p>
        <div class="row">
            <div class="col-12">
                <div
                    class="card border d-flex justify-content-center text-center"
                    style="height: 150px"
                >
                    <div class="align-self-center mb-3">
                        <img src="@/assets/upload.png" width="100px" />
                    </div>
                    <button
                        class="btn btn-sm btn-primary align-self-center"
                        style="width: 15%"
                        @click="uploadAttachment"
                        >
                        Upload File
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="div">
                        <input
                            hidden
                            type="file"
                            class="form-control"
                            ref="uploadFieldAttachment"
                            @change="selectFileAttachment"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2" v-for="(item, index) in document_status_attachments" :key="item.file">
            <CCardLink :href="item.file" target="_blank" class="">
                <img src="@/assets/attachment.png" width="100%" />
            </CCardLink>
            <button
                class="btn btn-danger btn-sm btn-block mt-2"
                @click="deleteFile(index)"
            >
                Hapus
            </button>
            <br />
            </div>
        </div>
        <template slot="footer">
            <div>
            <button @click="submitRevisi" class="btn btn-primary">
                Submit
            </button>
            </div>
        </template>
    </CModal>
  </div>

  
</template>

<script>
import { uploadFile } from "@/utils/fileUpload";
export default {
    data() {
        return {
            document: [],
            user: {},
            createModal: false,
            createModalRevisi: false,
            draft_sk:'',
            catatan: '',
            document_status_attachments: [],
            sk_sakti: [370,372,374,375,376,357,358,356,359,377,353,351,354,341,338,306,373,585,582]
        }
    },
    methods: {
        isShow(document) {
            // check sk 
            if (document.document_type.name == 'KEPUTUSAN SEKDA') {
                if (document.status !== 'APPROVED SEKDA') {
                    return false;
                } else {
                    return true;
                }
            } else {
                if (document.status !== 'APPROVED BUPATI') {
                    return false;
                } else {
                    return true;
                }
            }
        },
        rollbackMergePenomoran() {
            this.$swal.fire({
                icon: 'warning',
                html: 'Apakah yakin akan mengembalikan status PENOMORAN BELUM MERGE ke APPROVED KABAG ?',
                showCancelButton: true,
                confirmButtonText: 'Ya',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    var loading = this.$loading.show();
                    this.$store
                        .dispatch('document/rollbackMergePenomoran', this.document.id)
                        .then(() => {
                            this.$swal.fire({
                                icon: 'success',
                                text: 'Berhasil Rollback Merge Penomoran!',
                            });
                            this.getData();
                        })
                        .catch((e) => {
                            console.log(e)
                        })
                        .finally(() => {
                            loading.hide();
                        });
                } 
            })
        },
        rollbackPenomoran() {
            this.$swal.fire({
                icon: 'warning',
                html: 'Apakah yakin akan mengembalikan status ke PENOMORAN BELUM MERGE ?',
                showCancelButton: true,
                confirmButtonText: 'Ya',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    var loading = this.$loading.show();
                    this.$store
                        .dispatch('document/rollbackPenomoran', this.document.id)
                        .then(() => {
                            this.$swal.fire({
                                icon: 'success',
                                text: 'Berhasil Rollback Penomoran!',
                            });
                            this.getData();
                        })
                        .catch((e) => {
                            console.log(e)
                        })
                        .finally(() => {
                            loading.hide();
                        });
                } 
            })
        },
        isDownload() {
            if (this.user.verificator == 0) {
                if ((this.document.document_type.name == 'KEPUTUSAN BUPATI' && !this.document.bupati_verified_at) || 
                    (this.document.document_type.name == 'KEPUTUSAN SEKDA' && !this.document.sekda_verified_at)) {
                    return '#toolbar=0';
                } else {
                    return '';
                }
            } else {
                return '';
            }
        },
        penomoran() {
            const form = {
                id: this.document.id,
                total_attachment: this.document.total_attachment,
                document_type: this.document.document_type,
                document_type_id: this.document.document_type_id,
                document_activity_id: this.document.document_activity_id,
                title: this.document.tittle,
                document_number: this.document.document_number,
                signer: this.document.signer,
                proposer: this.document.proposer,
                unit: this.document.unit,
                date: this.document.date,
                determination_date: this.document.determination_date,
                penomoran: true,
            }

            const verificator = {
                admin_verified: this.document.admin_verified,
                admin_verified_at: this.document.admin_verified_at,
                legal_drafter_verified: this.document.legal_drafter_verified,
                legal_drafter_verified_at: this.document.legal_drafter_verified_at,
                suncang_verified: this.document.suncang_verified,
                suncang_verified_at: this.document.suncang_verified_at,
                kasubag_verified: this.document.kasubag_verified,
                kasubag_verified_at: this.document.kasubag_verified_at,
                kabag_verified: this.document.kabag_verified,
                kabag_verified_at: this.document.kabag_verified_at,
                asistant_verified: this.document.asistant_verified,
                asistant_verified_at: this.document.asistant_verified_at,
                sekda_verified: this.document.sekda_verified,
                sekda_verified_at: this.document.sekda_verified_at,
                bupati_verified: this.document.bupati_verified,
                bupati_verified_at: this.document.bupati_verified_at
            }

            // convert string to json
            if (this.document.document_decision.length > 0) {
                this.document.document_decision.forEach(element => {
                    if (element.extend_form) {
                        element.extend_form = JSON.parse(element.extend_form);
                    } else {
                        element.extend_form = [];
                    }
                });
            }
            
            this.$store.commit('document/setDocumentType', this.document.document_type.name);
            this.$store.commit('document/setDocument', form);
            this.$store.commit('document/setDocumentRemembers', this.document.document_remember);
            this.$store.commit('document/setDocumentNotices', this.document.document_notice);
            this.$store.commit('document/setDocumentDecisions', this.document.document_decision);
            this.$store.commit('document/setDocumentAttachments', this.document.document_attachment);
            this.$store.commit('document/setDocumentSupports', this.document.document_support);
            this.$store.commit('document/setDocumentConsiders', this.document.document_consider);
            this.$store.commit('document/setDocumentCopies', this.document.document_copy);
            this.$store.commit('document/setVerificator', verificator);
            this.$router.push({ path: 'DocumentFrom' });
        },
        uploadEoffice() {
            this.$swal.fire({
                icon: 'warning',
                html: 'Apakah yakin akan mengupload dokument ke e-office ?',
                showCancelButton: true,
                confirmButtonText: 'Ya',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    var loading = this.$loading.show();
                    this.$store
                        .dispatch('document/uploadEoffice', this.document.id)
                        .then(() => {
                            this.$swal.fire({
                                icon: 'success',
                                text: 'Berhasil Upload Document Ke E-Office!',
                            });
                            this.getData();
                        })
                        .catch((e) => {
                            console.log(e)
                        })
                        .finally(() => {
                            loading.hide();
                        });
                } 
            })
        },
        edit() {
            const form = {
                id: this.document.id,
                total_attachment: this.document.total_attachment,
                document_type: this.document.document_type,
                document_type_id: this.document.document_type_id,
                document_activity_id: this.document.document_activity_id,
                title: this.document.tittle,
                document_number: this.document.document_number,
                signer: this.document.signer,
                proposer: this.document.proposer,
                unit: this.document.unit,
                date: this.document.date,
                determination_date: this.document.determination_date,
                penomoran: false,
                draft_sk: this.document.draft_sk,
                qrcode: this.document.qrcode
            }

            const verificator = {
                admin_verified: this.document.admin_verified,
                admin_verified_at: this.document.admin_verified_at,
                legal_drafter_verified: this.document.legal_drafter_verified,
                legal_drafter_verified_at: this.document.legal_drafter_verified_at,
                suncang_verified: this.document.suncang_verified,
                suncang_verified_at: this.document.suncang_verified_at,
                kasubag_verified: this.document.kasubag_verified,
                kasubag_verified_at: this.document.kasubag_verified_at,
                kabag_verified: this.document.kabag_verified,
                kabag_verified_at: this.document.kabag_verified_at,
                asistant_verified: this.document.asistant_verified,
                asistant_verified_at: this.document.asistant_verified_at,
                sekda_verified: this.document.sekda_verified,
                sekda_verified_at: this.document.sekda_verified_at,
                bupati_verified: this.document.bupati_verified,
                bupati_verified_at: this.document.bupati_verified_at
            }

            // convert string to json
            if (this.document.document_decision.length > 0) {
                this.document.document_decision.forEach(element => {
                    if (element.extend_form) {
                        element.extend_form = JSON.parse(element.extend_form);
                    } else {
                        element.extend_form = [];
                    }
                });
            }
            
            this.$store.commit('document/setDocumentType', this.document.document_type.name);
            this.$store.commit('document/setDocument', form);
            this.$store.commit('document/setDocumentRemembers', this.document.document_remember);
            this.$store.commit('document/setDocumentNotices', this.document.document_notice);
            this.$store.commit('document/setDocumentDecisions', this.document.document_decision);
            this.$store.commit('document/setDocumentAttachments', this.document.document_attachment);
            this.$store.commit('document/setDocumentSupports', this.document.document_support);
            this.$store.commit('document/setDocumentConsiders', this.document.document_consider);
            this.$store.commit('document/setDocumentCopies', this.document.document_copy);
            this.$store.commit('document/setVerificator', verificator);
            this.$router.push({ path: 'DocumentFrom' });
        },
        format_date(date) {
            return this.$moment(new Date(date)).format("Do MMMM YYYY HH:mm");
        },
        approve(id = null) {
          this.$swal({
            title: "Pastikan Surat Keputusan (SK) yang diunggah sudah diperiksa dengan teliti",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ya"
          }).then((result) => {
            if (!result.value) {
              return
            }
    
            if (this.user.verificator == 6 && this.document.status == 'TTE ASPEM') {
                this.$swal.fire({
                    icon: 'info',
                    html: `
                        <input type="text" id="swal-input1" class="swal2-input" placeholder="Masukan NIK">
                        <input type="password" id="swal-input2" class="swal2-input" placeholder="Masukan Passphrase">
                    `,
                    focusConfirm: false,
                    showLoaderOnConfirm: true,
                    didOpen: () => {
                        // Add event listener for the Enter key press event
                        document.getElementById("swal-input2").addEventListener('keyup', (event) => {
                            if (event.key === 'Enter') {
                                // Trigger the modal's confirm button click
                                document.querySelector('.swal2-confirm').click();
                            }
                        });
                    },
                    preConfirm: () => {
                        if (document.getElementById("swal-input1").value && document.getElementById("swal-input2").value) {

                            let data = {
                                document_id : this.$route.query.id,
                                remark : "DI APPROVE & TTE ASSISTANT",
                                status : "APPROVED ASSISTANT",
                                sign : "TTD ASSISTANT",
                                legal : id,
                                nik: document.getElementById("swal-input1").value,
                                passphrase: document.getElementById("swal-input2").value
                            }

                            let path = "document/approveAssistant";
                            
                            var loading = this.$loading.show();
                            this.$store
                                .dispatch(path, data)
                                .then(() => {
                                    this.$swal.fire({
                                        icon: 'success',
                                        text: 'Berhasil Mengapprove Document!',
                                    });
                                    this.getData();
                                })
                                .catch((e) => {
                                    console.log(e)
                                })
                                .finally(() => {
                                    loading.hide();
                                });
                            
                        } else {
                            this.$swal.fire({
                                icon: 'error',
                                text: 'NIK atau Passphrase Wajib diisi!',
                            });
                        }
                    },
                })
            } else if ((this.user.verificator == 7 && this.document.status == 'APPROVED ASSISTANT') || (this.user.verificator == 7 && this.document.status == 'TTE ASPEM' && !this.document.asistant_verified)) {
                this.$swal.fire({
                    icon: 'info',
                    html: `
                        <input type="text" id="swal-input1" class="swal2-input" placeholder="Masukan NIK">
                        <input type="password" id="swal-input2" class="swal2-input" placeholder="Masukan Passphrase">
                    `,
                    focusConfirm: false,
                    showLoaderOnConfirm: true,
                    didOpen: () => {
                        // Add event listener for the Enter key press event
                        document.getElementById("swal-input2").addEventListener('keyup', (event) => {
                            if (event.key === 'Enter') {
                                // Trigger the modal's confirm button click
                                document.querySelector('.swal2-confirm').click();
                            }
                        });
                    },
                    preConfirm: () => {
                        if (document.getElementById("swal-input1").value && document.getElementById("swal-input2").value) {

                            let data = {
                                document_id : this.$route.query.id,
                                remark : "DI APPROVE & TTE SEKDA",
                                status : "APPROVED SEKDA",
                                sign : "TTD SEKDA",
                                legal : id,
                                nik: document.getElementById("swal-input1").value,
                                passphrase: document.getElementById("swal-input2").value
                            }

                            let path = "document/approveSekda";
                            
                            var loading = this.$loading.show();
                            this.$store
                                .dispatch(path, data)
                                .then(() => {
                                    this.$swal.fire({
                                        icon: 'success',
                                        text: 'Berhasil Mengapprove Document!',
                                    });
                                    this.getData();
                                })
                                .catch((e) => {
                                    console.log(e)
                                })
                                .finally(() => {
                                    loading.hide();
                                });
                            
                        } else {
                            this.$swal.fire({
                                icon: 'error',
                                text: 'NIK atau Passphrase Wajib diisi!',
                            });
                        }
                    },
                })
            } else if (this.user.verificator == 8 && this.document.status == 'UPLOAD KE E-OFFICE') {
                this.$swal.fire({
                    icon: 'info',
                    html: `
                        <input type="text" id="swal-input1" class="swal2-input" placeholder="Masukan NIK">
                        <input type="password" id="swal-input2" class="swal2-input" placeholder="Masukan Passphrase">
                    `,
                    focusConfirm: false,
                    showLoaderOnConfirm: true,
                    didOpen: () => {
                        // Add event listener for the Enter key press event
                        document.getElementById("swal-input2").addEventListener('keyup', (event) => {
                            if (event.key === 'Enter') {
                                // Trigger the modal's confirm button click
                                document.querySelector('.swal2-confirm').click();
                            }
                        });
                    },
                    preConfirm: () => {
                        if (document.getElementById("swal-input1").value && document.getElementById("swal-input2").value) {

                            let data = {
                                document_id : this.$route.query.id,
                                remark : "DI APPROVE & TTE BUPATI",
                                status : "APPROVED BUPATI",
                                sign : "TTD BUPATI",
                                legal : id,
                                nik: document.getElementById("swal-input1").value,
                                passphrase: document.getElementById("swal-input2").value
                            }

                            let path = "document/approveBupati";
                            
                            var loading = this.$loading.show();
                            this.$store
                                .dispatch(path, data)
                                .then(() => {
                                    this.$swal.fire({
                                        icon: 'success',
                                        text: 'Berhasil Mengapprove Document!',
                                    });
                                    this.getData();
                                })
                                .catch((e) => {
                                    console.log(e)
                                })
                                .finally(() => {
                                    loading.hide();
                                });
                            
                        } else {
                            this.$swal.fire({
                                icon: 'error',
                                text: 'NIK atau Passphrase Wajib diisi!',
                            });
                        }
                    },
                })
            } else {
                this.$swal.fire({
                    icon: 'warning',
                    html: 'Apakah yakin akan Mengapprove data?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya, Approve',
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        this.submit(id);
                    } 
                })
            }
          })
           
        },
        revisi(revisi) {

            let header = `<div class="row">`;
            let footer = `</div>`
            let document_status_attachments = "";
            if (revisi.document_status_attachments.length > 0) {
                revisi.document_status_attachments.forEach(element => {
                    document_status_attachments += `
                        <div class="col-md-3" >
                            <img src="https://firebasestorage.googleapis.com/v0/b/gps-tracker-3c2a1.appspot.com/o/attachment.bcbdbb94.png?alt=media&token=9092c620-9e1c-4a88-ae2f-f8a8b200bf16" width="100%" />
                            <br />
                            <a href="${element.file}" target="_blank" style="width: 100%;"
                                class="btn btn-sm btn-primary align-self-center"
                                >
                                Download
                            </a>
                        </div>
                    `;
                });
            } 

            document_status_attachments = header + document_status_attachments + footer;

            if (this.user.id == revisi.user_id) {
                let hasil = '';
                this.$swal.fire({
                    title: 'Catatan',
                    html: `
                        <textarea class="swal2-textarea" style="font-size: 12px; height: 300px; width: 400px;">${revisi.remark}</textarea>
                    ` + document_status_attachments,
                    showCancelButton: true,
                    confirmButtonText: 'Edit',
                    confirmButtonColor: '#3085d6',
                    customClass: {
                        popup: 'custom-popup-class',
                    },
                    preConfirm: () => {
                        // Mengambil nilai dari textarea setelah diklik tombol Edit
                        hasil = document.querySelector('.swal2-textarea').value;
                    }
                }).then((result) => {
                    // Menangani logika setelah pengguna mengklik tombol Close
                    if (result.value) {
                        let data = {
                            id: revisi.id,
                            revisi: hasil
                        }

                        let loading = this.$loading.show();
                        this.$store
                            .dispatch('document/editRevisi', data)
                            .then(() => {
                                this.$swal.fire({
                                    icon: 'success',
                                    text: 'Berhasil Edit Revisi!',
                                });
                                loading.hide();
                                this.getData();
                            })
                            .catch((e) => {
                                loading.hide();
                                console.log(e)
                            })
                            .finally(() => {
                                loading.hide();
                            });
                    }
                });
            } else {
                this.$swal.fire({
                    title: 'Catatan',
                    html: `<textarea class="swal2-textarea" style="font-size: 12px; height: 200px; width: 400px;" readonly>${revisi.remark}</textarea>` + document_status_attachments,
                    showCancelButton: false,
                    confirmButtonText: 'Close',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    customClass: {
                        popup: 'custom-popup-class',
                    },
                });
            }
        },
        submit(id = null) {
            let data = {
                document_id : this.$route.query.id,
                remark : null,
                status :null,
                sign : null,
                legal : id
            }

            let path = null;

            if(this.user.verificator === 1){
                console.log("User is a verificator");
                data.remark = "DI APPROVE ADMIN";
                data.status = "PROSES";
                path = "document/approveAdmin";
            }
            else if(this.user.verificator === 2){
                data.remark = "DI APPROVE LEGAL DRAFTER";
                data.status = "LEGAL DRAFTING";
                path = "document/approveLegalDrafter";
            }
            else if(this.user.verificator === 3){
                data.remark = "DI APPROVE SUNCANG";
                data.status = "APPROVED SUNCANG";
                path = "document/approveSuncang";
            }
            else if(this.user.verificator === 4){
                data.remark="DI APPROVE ANALIS HUKUM";
                data.status="APPROVED ANALIS HUKUM";
                path ="document/approveKasubag";
                data.sign = "TTD Kasubag"
            }
            else if(this.user.verificator === 5){
                data.remark="DI APPROVE KABAG";
                data.status="APPROVED KABAG";
                path ="document/approveKabag";
                data.sign = "TTD Kabag"
            }
            else if(this.user.verificator === 6){
                data.remark="DI APPROVE ASSISTANT";
                data.status="APPROVED ASSISTANT";
                path ="document/approveAssistant";
                data.sign = "TTD ASSISTANT"
                
            }
            else if(this.user.verificator === 7){
                data.remark="DI APPROVE SEKDA";
                data.status="APPROVED SEKDA";
                path ="document/approveSekda";
                data.sign = "TTD Sekda"
            } 
            else if(this.user.verificator === 8){
                data.remark="DI APPROVE BUPATI";
                data.status="APPROVED BUPATI";
                path ="document/approveBupati";
                data.sign = "TTD Bupati"
            } 

            var loading = this.$loading.show();
            this.$store
                .dispatch(path, data)
                .then(() => {
                    this.$swal.fire({
                        icon: 'success',
                        text: 'Berhasil Mengapprove Document!',
                    });
                    this.getData();
                })
                .catch((e) => {
                    console.log(e)
                })
                .finally(() => {
                    loading.hide();
                });
        },
        merge() {
          this.$swal({
            title: "Pastikan Surat Keputusan (SK) yang diunggah sudah diperiksa dengan teliti",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ya"
          }).then((result) => {
            if (!result.value) {
              return
            }

            var loading = this.$loading.show();
            this.$store
                .dispatch('document/updateNaskah', {
                    id: this.$route.query.id,
                    draft_sk: this.draft_sk,
                })
                .then(() => {
                    this.$swal.fire({
                        icon: 'success',
                        text: 'Berhasil Merge Document!',
                    });
                    this.draft_sk = '';
                    this.createModal = false;
                    this.getData();
                })
                .catch((e) => {
                    console.log(e)
                })
                .finally(() => {
                    loading.hide();
                });
          })
            
        },
        rePropose() {

            this.$swal.fire({
                icon: 'warning',
                html: 'Apakah yakin akan mengajukan kembali?',
                showCancelButton: true,
                confirmButtonText: 'Ya, Ajukan',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    let data = {
                        document_id : this.$route.query.id,
                        remark : "DI AJUKAN KEMBALI",
                        status : "PENGAJUAN",
                    }

                    let path = "document/rePropose";
                    let loading = this.$loading.show();
                    this.$store
                        .dispatch(path, data)
                        .then(() => {
                            this.$swal.fire({
                                icon: 'success',
                                text: 'Berhasil Diajukan Kembali!',
                            });
                            this.getData();
                        })
                        .catch((e) => {
                            console.log(e)
                        })
                        .finally(() => {
                            loading.hide();
                        });
                } 
            })
        },
        return_doc() {
            this.createModalRevisi = true;

            // const vm = this;
            // this.$swal.fire({
            //     icon: 'info',
            //     html: `
            //         Silahkan memberikan alasan / keterangan terlebih dahulu!<br /> Judul: 
            //             ${this.document.tittle}
            //     `,
            //     customClass: {
            //         popup: 'custom-popup-class',
            //     },
            //     input: 'textarea',
            //     inputAttributes: {
            //         autocapitalize: 'off'
            //     },
            //     showCancelButton: true,
            //     confirmButtonText: 'Kirim',
            //     showLoaderOnConfirm: true,
            //     backdrop: false, 
            //     preConfirm: (alasan) => {

            //         let data = {
            //             document_id : this.$route.query.id,
            //             remark : null,
            //             status :null,
            //             sign : null
            //         }

            //         if(this.user.verificator === 1 || this.user.verificator === 2){
            //             data.remark = alasan;
            //             data.status = "DIKEMBALIKAN";
            //             let path = "";
            //             if(this.user.verificator === 2){
            //                  path = "document/approveLegalDrafter";
            //             } else {
            //                 path = "document/approveAdmin";
            //             }
            //             let loading = this.$loading.show();
            //             this.$store
            //                 .dispatch(path, data)
            //                 .then(() => {
            //                     this.$swal.fire({
            //                         icon: 'success',
            //                         text: 'Berhasil Mengembalikan Document!',
            //                     });
            //                     this.getData();
            //                 })
            //                 .catch((e) => {
            //                     loading.hide();
            //                     console.log(e)
            //                 })
            //                 .finally(() => {
            //                     loading.hide();
            //                 });
            //         }
            //         else {
            //             data.remark = alasan + "\n\n\ncode:" + this.$route.query.id;
            //             data.status = "REVISI";
            //             let path;
            //             if(this.user.verificator === 2){
            //                  path = "document/approveLegalDrafter";
            //             }
            //             else if(this.user.verificator === 3){
            //                 path = "document/approveSuncang";
            //             }
            //             else if(this.user.verificator === 4){
            //                 path = "document/approveKasubag";
            //             }
            //             else if(this.user.verificator === 5){
            //                 path = "document/approveKabag";
            //             }
            //             else if(this.user.verificator === 6){
            //                 path = "document/approveAssistant";
            //             }
            //             else if(this.user.verificator === 7){
            //                 path = "document/approveSekda";
            //             }
            //             else if(this.user.verificator === 8){
            //                 path = "document/approveBupati";
            //             }
                        
            //             let loading = this.$loading.show();
            //             this.$store
            //                 .dispatch(path, data)
            //                 .then(() => {
            //                     this.$swal.fire({
            //                         icon: 'success',
            //                         text: 'Berhasil Menambahkan Status Revisi!',
            //                     });
            //                     this.getData();
            //                 })
            //                 .catch((e) => {
            //                     loading.hide();
            //                     console.log(e)
            //                 })
            //                 .finally(() => {
            //                     loading.hide();
            //                 });
            //         } 
            //     },
            // })
        },
        submitRevisi() {

            let data = {
                document_id : this.$route.query.id,
                remark : this.catatan,
                status :null,
                sign : null,
                document_status_attachments: this.document_status_attachments
            }

            if(this.user.verificator === 1 || this.user.verificator === 2){
                data.remark = this.catatan;
                data.status = "DIKEMBALIKAN";
                let path = "";
                if(this.user.verificator === 2){
                        path = "document/approveLegalDrafter";
                } else {
                    path = "document/approveAdmin";
                }
                let loading = this.$loading.show();
                this.$store
                    .dispatch(path, data)
                    .then(() => {
                        this.$swal.fire({
                            icon: 'success',
                            text: 'Berhasil Mengembalikan Document!',
                        });
                        this.getData();
                        this.createModalRevisi = false;
                    })
                    .catch((e) => {
                        loading.hide();
                        console.log(e)
                    })
                    .finally(() => {
                        loading.hide();
                    });
            }
            else {
                data.remark = this.catatan + "\n\n\ncode:" + this.$route.query.id;
                data.status = "REVISI";
                let path;
                if(this.user.verificator === 2){
                        path = "document/approveLegalDrafter";
                }
                else if(this.user.verificator === 3){
                    path = "document/approveSuncang";
                }
                else if(this.user.verificator === 4){
                    path = "document/approveKasubag";
                }
                else if(this.user.verificator === 5){
                    path = "document/approveKabag";
                }
                else if(this.user.verificator === 6){
                    path = "document/approveAssistant";
                }
                else if(this.user.verificator === 7){
                    path = "document/approveSekda";
                }
                else if(this.user.verificator === 8){
                    path = "document/approveBupati";
                }
                
                let loading = this.$loading.show();
                this.$store
                    .dispatch(path, data)
                    .then(() => {
                        this.$swal.fire({
                            icon: 'success',
                            text: 'Berhasil Menambahkan Status Revisi!',
                        });
                        this.getData();
                        this.createModalRevisi = false;
                    })
                    .catch((e) => {
                        loading.hide();
                        console.log(e)
                    })
                    .finally(() => {
                        loading.hide();
                    });
            }
        },
        selectFileRevisi(file) {
            this.file = event.target.files[0];
            var loading = this.$loading.show();
            uploadFile(this.file)
                .then((resp) => {
                    this.document_supports.push({file: resp});
                    loading.hide();
                    this.$swal.fire({
                        icon: 'success',
                        text: 'File berhasil diupload !!',
                    });
                })
                .catch((e) => {
                    loading.hide();
                    this.$swal.fire({
                        icon: 'warning',
                        text: 'Terjadi kesalahan, ' + e,
                    })
                });
        },
        komentar() {
            this.$swal.fire({
                icon: 'info',
                html: 'Silahkan memberikan Komentar / keterangan terlebih dahulu!<br/> Judul: ' + this.document.tittle,
                input: 'textarea',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: 'Kirim',
                showLoaderOnConfirm: true,
                preConfirm: (alasan) => {

                    let data = {
                        document_id : this.$route.query.id,
                        remark : null,
                        status :null,
                        sign : null
                    }

                    data.remark = alasan + "\n\n\ncode:" + this.$route.query.id;
                    data.status = "KOMENTAR";
                    let path = "document/komentar";
                    let loading = this.$loading.show();
                    this.$store
                        .dispatch(path, data)
                        .then(() => {
                            this.$swal.fire({
                                icon: 'success',
                                text: 'Berhasil Memberi Komentar Document!',
                            });
                            this.getData();
                        })
                        .catch((e) => {
                            loading.hide();
                            console.log(e)
                        })
                        .finally(() => {
                            loading.hide();
                        });
                },
            })
        },
        tteKabag() {

            this.$swal.fire({
                icon: 'info',
                html: `
                    <input type="text" id="swal-input1" class="swal2-input" placeholder="Masukan NIK">
                    <input type="password" id="swal-input2" class="swal2-input" placeholder="Masukan Passphrase">
                `,
                focusConfirm: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    if (document.getElementById("swal-input1").value && document.getElementById("swal-input2").value) {

                        let data = {
                            document_id : this.$route.query.id,
                            nik: document.getElementById("swal-input1").value,
                            passphrase: document.getElementById("swal-input2").value
                        }

                        let loading = this.$loading.show();
                        this.$store
                            .dispatch('document/tteKabag', data)
                            .then(() => {
                                this.$swal.fire({
                                    icon: 'success',
                                    text: 'Berhasil Memberi TTE Document!',
                                });
                                this.getData();
                            })
                            .catch((e) => {
                                loading.hide();
                                console.log(e)
                            })
                            .finally(() => {
                                loading.hide();
                            });
                        
                    } else {
                        this.$swal.fire({
                            icon: 'error',
                            text: 'NIK atau Passphrase Wajib diisi!',
                        });
                    }
                },
            })
        },
        getData() {
            let loading = this.$loading.show();
            this.$store.dispatch("document/show", this.$route.query.id).then(
                (data) => {
                    const result = data;
                    
                    if (result.status == 200) {
                        this.document = result.data;
                        for (let index = 1; index < this.document.document_status.length; index++) {
                            const currentRecord = this.document.document_status[index];
                            const prevRecord = this.document.document_status[index - 1];
                            const currentDateTime = new Date(`${currentRecord.created_at}`);
                            const prevDateTime = new Date(`${prevRecord.created_at}`);
                            const diffInMilliseconds = currentDateTime - prevDateTime;

                            // Menghitung durasi dalam bentuk jam, menit, dan detik
                            const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
                            const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
                            const seconds = Math.floor((diffInMilliseconds % (1000 * 60)) / 1000);

                            // Format durasi
                            currentRecord.durasi = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                        }
                    }
                    
                    loading.hide();
                },
                (error) => {
                    loading.hide();
                }
            );
        },
        addModal() {       
            this.createModal = true;
        },
        upload() {
            this.$refs.uploadField.click();
        },
        uploadAttachment() {
            this.$refs.uploadFieldAttachment.click();
        },
        selectFile(event) {
            this.file = event.target.files[0];
            
            if (this.file.type == "application/pdf") {
                var loading = this.$loading.show();
                uploadFile(this.file)
                    .then((resp) => {
                        
                        this.draft_sk = resp;
                        loading.hide();
                        this.$swal.fire({
                            icon: 'success',
                            text: 'File berhasil diupload !!',
                        });
                    })
                    .catch((e) => {
                        loading.hide();
                        this.$swal.fire({
                            icon: 'warning',
                            text: 'Terjadi kesalahan, ' + e,
                        })
                    });
            } else {
                this.$swal.fire({
                    icon: 'error',
                    text: 'Format dokumen harus berupa PDF',
                });
            }
            
        },
        selectFileAttachment(event) {
            this.file = event.target.files[0];
            var loading = this.$loading.show();
            uploadFile(this.file)
                .then((resp) => {
                    this.document_status_attachments.push({file: resp});
                    loading.hide();
                    this.$swal.fire({
                        icon: 'success',
                        text: 'File berhasil diupload !!',
                    });
                })
                .catch((e) => {
                    loading.hide();
                    this.$swal.fire({
                        icon: 'warning',
                        text: 'Terjadi kesalahan, ' + e,
                    })
                });
        },
        hitungDurasi(current_data, before_data) {
            console.log(current_data, before_data, "ini")
            return 0
        },
        disableRightClick(event) {
            event.preventDefault();
        },
        handleIframeLoad() {
            const iframe = this.$refs.myIframe;

            if (iframe && iframe.contentWindow) {
                iframe.contentWindow.document.addEventListener('contextmenu', this.disableRightClick);
            } else {
                console.error('Iframe or contentWindow is undefined.');
            }
        },
    },
    beforeDestroy() {
        // Clean up the event listener when the component is destroyed
        this.$refs.myIframe.contentWindow.document.removeEventListener('contextmenu', this.disableRightClick);
    },
    mounted() {
        this.getData();
        const user = JSON.parse(localStorage.getItem('user'));
        this.user = user;


        // Menambahkan event listener setelah iframe terisi
    this.$refs.myIframe.onload = () => {
      const pdfIframe = this.$refs.myIframe;
      const pdfDocument = pdfIframe.contentWindow.document;

      // Menyembunyikan tombol download
      const downloadButton = pdfDocument.querySelector('.download-button-class');
      if (downloadButton) {
        downloadButton.style.display = 'none';
      }

      // Menyembunyikan tombol print
      const printButton = pdfDocument.querySelector('.print-button-class');
      if (printButton) {
        printButton.style.display = 'none';
      }
    };
        
    },
    computed: {
       
    }
}
</script>

<style>
    .custom-popup-class {
       width: 600px; /* Adjust the height as needed */
       /*max-height: 80vh;  Set a maximum height if necessary */
    }
</style>
