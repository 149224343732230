<template>
    <div class="container">
        <template v-if="document_type == 'INSTRUKSI BUPATI'">
            <div v-for="(item, index) in document_considers" :key="index" :set="v = $v.document_considers.$each[index]">
                <table class="table">
                    <tr>
                        <td style="vertical-align: baseline;width: 15px;"><strong>{{ index +1 }}.</strong></td>
                        <td>
                            <div class="form-group">
                                <!-- <textarea type="text" :class="['form-control', (!v.description.required) ? 'is-invalid' : '']"  v-model="item.description" id="title" aria-describedby="title" placeholder="Ketik Menimbang" rows="3"></textarea> -->
                                <vue-editor :editor-toolbar="customToolbar"
                                    v-model="item.description" id="title" aria-describedby="title" placeholder="Ketik Menimbang"></vue-editor>
                                <small id="document_type" v-if="!v.description.required" class="form-text text-muted">Deskripsi Menimbang Wajid Diisi!</small>
                            </div>
                            <div class="form-check mb-3">
                                <input class="form-check-input" type="checkbox" v-model="item.is_setting_margin"  id="flexCheckDefault">
                                <label class="form-check-label" for="flexCheckDefault">
                                    Setting Margin
                                </label>
                            </div>
                            <div class="row" v-if="item.is_setting_margin">
                                <div class="form-group col">
                                    <label for="date">Margin Atas</label>
                                    <input type="number" class="form-control"  v-model="item.margin_top" id="title" aria-describedby="title" placeholder="Margin Atas">
                                </div>
                                <div class="form-group col">
                                    <label for="date">Margin Bawah</label>
                                    <input type="number" class="form-control"  v-model="item.margin_bottom" id="title" aria-describedby="title" placeholder="Margin Bawah">
                                </div>
                                <div class="form-group col">
                                    <label for="date">Line Space</label>
                                    <input type="number" class="form-control"  v-model="item.margin_left" id="title" aria-describedby="title" placeholder="Line Space">
                                </div>
                                <!-- <div class="form-group col">
                                    <label for="date">Margin Kanan</label>
                                    <input type="number" class="form-control"  v-model="item.margin_right" id="title" aria-describedby="title" placeholder="Margin Kanan">
                                </div> -->
                            </div>
                        </td>
                        <td style="width: 20px;vertical-align: baseline;">
                            <button @click="deleteDetail(index)" class="btn btn-sm btn-danger m-1">Hapus</button>
                        </td>
                        <td style="width: 20px;vertical-align: baseline;">
                            <button @click="move(index,index-1)" :disabled="index==0" class="btn btn-sm btn-success m-1">Naik</button>
                        </td>
                        <td style="width: 20px;vertical-align: baseline;">
                            <button @click="move(index,index+1)" :disabled="index==(document_considers.length-1)" class="btn btn-sm btn-warning m-1">Turun</button>
                        </td>
                    </tr>
                </table>
            </div>
            <button @click="add" class="btn btn-sm btn-success"><CIcon name="cilMedicalCross" /> Tambah Paragraf </button>
        </template>
        <template v-else>
            <div v-for="(item, index) in document_considers" :key="index" :set="v = $v.document_considers.$each[index]">
                <table class="table">
                    <tr>
                        <td style="vertical-align: baseline;width: 15px;"><strong>{{ index +1 }}.</strong></td>
                        <td>
                            <div class="form-group">
                                <!-- <textarea type="text" :class="['form-control', (!v.description.required) ? 'is-invalid' : '']"  v-model="item.description" id="title" aria-describedby="title" placeholder="Ketik Menimbang" rows="3"></textarea> -->
                                <vue-editor :editor-toolbar="customToolbar"
                                    v-model="item.description" id="title" aria-describedby="title" placeholder="Ketik Menimbang"></vue-editor>
                                <small id="document_type" v-if="!v.description.required" class="form-text text-muted">Deskripsi Menimbang Wajid Diisi!</small>
                            </div>
                            <div class="form-check mb-3">
                                <input class="form-check-input" type="checkbox" v-model="item.is_setting_margin"  id="flexCheckDefault">
                                <label class="form-check-label" for="flexCheckDefault">
                                    Setting Margin
                                </label>
                            </div>
                            <div class="row" v-if="item.is_setting_margin">
                                <div class="form-group col">
                                    <label for="date">Margin Atas</label>
                                    <input type="number" class="form-control"  v-model="item.margin_top" id="title" aria-describedby="title" placeholder="Margin Atas">
                                </div>
                                <div class="form-group col">
                                    <label for="date">Margin Bawah</label>
                                    <input type="number" class="form-control"  v-model="item.margin_bottom" id="title" aria-describedby="title" placeholder="Margin Bawah">
                                </div>
                                <div class="form-group col">
                                    <label for="date">Line Space</label>
                                    <input type="number" class="form-control"  v-model="item.margin_left" id="title" aria-describedby="title" placeholder="Line Space">
                                </div>
                                <!-- <div class="form-group col">
                                    <label for="date">Margin Kanan</label>
                                    <input type="number" class="form-control"  v-model="item.margin_right" id="title" aria-describedby="title" placeholder="Margin Kanan">
                                </div> -->
                            </div>
                        </td>
                        <td style="width: 20px;vertical-align: baseline;">
                            <button @click="deleteDetail(index)" class="btn btn-sm btn-danger m-1">Hapus</button>
                        </td>
                        <td style="width: 20px;vertical-align: baseline;">
                            <button @click="move(index,index-1)" :disabled="index==0" class="btn btn-sm btn-success m-1">Naik</button>
                        </td>
                        <td style="width: 20px;vertical-align: baseline;">
                            <button @click="move(index,index+1)" :disabled="index==(document_considers.length-1)" class="btn btn-sm btn-warning m-1">Turun</button>
                        </td>
                    </tr>
                </table>
            </div>
            <button @click="add" class="btn btn-sm btn-success"><CIcon name="cilMedicalCross" /> Tambah Menimbang </button>
        </template>
        
    </div>
</template>

<script>
    import {validationMixin} from 'vuelidate'
    import { required } from 'vuelidate/lib/validators'
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import { VueEditor } from "vue2-editor";

    Array.prototype.move = function(from, to) {
        this.splice(to, 0, this.splice(from, 1)[0]);
        return this;
    };
    export default {
        props: ['clickedNext', 'currentStep'],
        mixins: [validationMixin],
        data() {
            return {
                document_type: "",
                document_considers: [
                    {
                        description: '',
                        margin_top: 0,
                        margin_left: 0,
                        margin_bottom: 0,
                        margin_right: 0,
                    }
                ],
                customToolbar: [
                    ["bold", "italic", "underline"],
                ],
            }
        },
        components: {
            VueEditor
        },
        validations: {
            document_considers: {
                $each: {
                    description: { required },
                }
            }
        },
        watch: {
            $v: {
                handler: function (val) {
                    if(!val.$invalid && this.document_considers.length > 0) {
                        this.$store.commit('document/setDocumentConsiders', this.document_considers);
                        this.$emit('can-continue', {value: true});
                    } else {
                        this.$emit('can-continue', {value: false});
                    }
                },
                deep: true
            },
            currentStep(val) {
                if(!this.$v.invalid) {
                    this.$emit('can-continue', {value: true});
                } else {
                    this.$emit('can-continue', {value: false});
                }
            }
        },
        methods: {
            add() {
                this.document_considers.push({
                    description: '',
                    margin_top: 0,
                    margin_left: 0,
                    margin_bottom: 0,
                    margin_right: 0,
                })
            },
            deleteDetail(index) {
                this.document_considers.splice(index, 1);
            },
            move(from, to) {
                this.document_considers.move(from, to);
            }
        },
        mounted() {
            this.document_type = this.$store.state.document.document_type;
            let _document_considers = this.$store.state.document.document_considers;
            if (_document_considers.length > 0) {
                this.document_considers = _document_considers;
            }
            
            if(!this.$v.$invalid) {
                this.$emit('can-continue', {value: true});
            } else {
                this.$emit('can-continue', {value: false});
            }
        }
    }

        
</script>