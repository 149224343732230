export const fields = [
    { key: 'index', label: "No", _style:'width:10px'},
    { key: "action", label: 'Aksi', _style: 'width:75px'},
    { key: 'status', label: "Status", _style:'min-width:100px' },
    // { key: 'aksi', label: "Tindakan", _style:'min-width:100px' },
    { key: 'document_type', label: "Jenis Dokumen", _style:'min-width:100px' },
    { key: 'tittle', label: "Judul", _style:'min-width:100px' },
    { key: 'legal_drafter', label: "Legal Drafter", _style:'min-width:100px' },
    // { key: 'phone_number', label: "Phone Number", _style:'min-width:100px' },
    { key: 'user_name', label: "Penyusun", _style:'min-width:100px' },
    { key: 'regional_apparatus', label: "Perangkat Daerah", _style:'min-width:100px' },
    { key: 'date', label: "Tanggal Pengajuan", _style:'min-width:100px' },
    { key: 'created_at', label: "Tanggal Dibuat", _style:'min-width:100px' },
    { key: 'updated_at', label: "Tanggal Update", _style:'min-width:100px' },
]
