<template>
     <div>
        <div style="text-align: center; margin-top: 2px"><img src="@/assets/logo/garuda.png" width="113.3px" height="113.3px" /></div>
        <p style="text-align:center; margin-top:20px">BUPATI BANDUNG</p>
        <p style="text-align:center; margin:0">PROVINSI JAWA BARAT</p>
        <p style="text-align:center; margin:0; line-height: 3">INSTRUKSI BUPATI BANDUNG</p>
    </div>
  </template>
  
  <script>

  export default {
    // Component's data properties
    data() {
      return {
        
      };
    },
    // Component's methods
    methods: {
      
    },
  };
  </script>