import axios from 'axios'; 


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {

        // async getKinerja(_) {
        //     try {
        //       let response = await axios.get('user/get-kinerja/');
        //       let data = response.data;
        //       return data; // Resolve the data
        //     } catch (e) {
        //       console.log(e);
        //       throw e; // Throw the error to be caught by the caller
        //     }
        //   },
          getKinerja(){
            return new Promise((resolve, reject) => {
                axios.get('user/get-kinerja').then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            })
        },
          
          
    },
    getters: {

    }

}