<template>
    <div class="container">
        
        <template v-if="document_type == 'PERATURAN BUPATI'">
            <!-- <label>MENETAPKAN :</label>
           <vue-editor :editor-toolbar="customToolbar"
                                v-model="document_decisions[0].description"
                                placeholder="Ketik disini..."
            ></vue-editor>
            <br /> -->
            <p>MENETAPKAN : <strong>PERATURAN BUPATI TENTANG <span v-html="document.title"></span></strong></p>
            <br />
            <!-- {{ document_decisions }} -->
            <div v-for="(item, index) in document_decisions" :key="index">
                <div :set="v = $v.document_decisions.$each[index]">
                    <table class="table table-borderless">
                        <tr>
                            <td style="vertical-align: baseline;width: 15px;"><strong>{{ index + 1 }}.</strong></td>
                            <td style="vertical-align: baseline;width: 20px;">BAB {{ index + 1 }}</td>
                            <td>
                                <input type="text" class="form-control" v-model="item.description" placeholder="Judul Bab ..." value="KETENTUAN UMUM"/>
                                <small id="document_type" v-if="!v.description.required" class="form-text text-muted">Deskripsi BAB Wajid Diisi!</small>

                                <table class="table table-borderless mb-0" v-if="item.extend_form !== ''">
                                    <tr v-for="(extend, index_extend) in item.extend_form" :key="index_extend">
                                        <template >
                                            <td class="p-1">
                                                <div class="form-group mb-0" v-if="extend.type == 'pasal'">
                                                    <label>PASAL {{ getNumberPasal(index, index_extend) }}</label>
                                                    <vue-editor :editor-toolbar="customToolbar"
                                                                        v-model="extend.text"
                                                                        placeholder="Ketik disini..."
                                                    ></vue-editor>
                                                </div>
                                                <div class="form-group mb-0" v-if="extend.type == 'pasal-ayat'">
                                                    <label>PASAL {{ getNumberPasal(index, index_extend) }}</label>
                                                    <div class="form-group mb-0" v-for="(ayat, index_ayat) in extend.ayat" :key="index_ayat">
                                                        <label>AYAT ({{ index_ayat + 1 }})</label>
                                                        <div class="row">
                                                            <div class="col-md-10">
                                                                <input type="text" class="form-control" v-model="ayat.value" placeholder="Input Ayat ..." />
                                                            </div>
                                                            <div class="col-md-2">
                                                                <button @click="removeAyat(index, index_extend,index_ayat )" class="btn btn-sm btn-danger m-1">Hapus</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button @click="addAyat(index, index_extend)" class="btn btn-sm btn-success mr-1 mt-2"><CIcon name="cilMedicalCross" /> AYAT</button>
                                                </div>
                                                <div class="form-group mb-0" v-if="extend.type == 'bagian'">
                                                    <label>Bagian {{ getNumberBagian(index, index_extend) }}</label>
                                                    <div class="form-group">
                                                        <input type="text" class="form-control" v-model="extend.text" placeholder="Input Isi Bagian ..." />
                                                    </div>
                                                </div>
                                                <div class="form-group mb-0" v-if="extend.type == 'paragraf'">
                                                    <label>Paragraf {{ getNumberParagraf(index, index_extend) }}</label>
                                                    <div class="form-group">
                                                        <input type="text" class="form-control" v-model="extend.text" placeholder="Input Isi Paragraf ..." />
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="p-1" style="width: 20px;vertical-align: baseline;">
                                                <button @click="deleteExtendForm(index, index_extend)" class="btn btn-sm btn-danger m-1">Hapus</button>
                                            </td>
                                        </template>
                                    </tr>
                                </table>

                                <button @click="addExtendForm(index, 'pasal')" class="btn btn-sm btn-success mr-1 mt-2"><CIcon name="cilMedicalCross" /> PASAL</button>
                                <button @click="addExtendForm(index, 'pasal-ayat')" class="btn btn-sm btn-success mr-1 mt-2"><CIcon name="cilMedicalCross" /> PASAL + AYAT</button>
                                <button @click="addExtendForm(index, 'bagian')" class="btn btn-sm btn-success mr-1 mt-2"><CIcon name="cilMedicalCross" /> BAGIAN</button>
                                <button @click="addExtendForm(index, 'paragraf')" class="btn btn-sm btn-success mr-1 mt-2"><CIcon name="cilMedicalCross" /> PARAGRAF</button>

                                <hr class="mt-2" />
                            </td>
                            <td style="width: 20px;vertical-align: baseline;">
                                <button @click="deleteDetail(index)" class="btn btn-sm btn-danger m-1">Hapus</button>
                                <button @click="move(index,index-1)" :disabled="index==0" class="btn btn-sm btn-success m-1">Naik</button>
                                <button @click="move(index,index+1)" :disabled="index==(document_decisions.length-1)" class="btn btn-sm btn-warning m-1">Turun</button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <button @click="add" class="btn btn-sm mt-3 btn-success"><CIcon name="cilMedicalCross" /> Tambah BAB</button>

        </template>

        <template v-else>
            <div v-for="(item, index) in document_decisions" :key="index" :set="v = $v.document_decisions.$each[index]">
                <table class="table table-borderless">
                    <tr>
                        <td style="vertical-align: baseline;width: 15px;"><strong>{{ index +1 }}.</strong></td>
                        <td>
                            <!-- <ckeditor tag-name="textarea" :editor="editor" v-model="item.description" :config="editorConfig"></ckeditor> -->
                            <vue-editor :editor-toolbar="customToolbar"
                                v-model="item.description"
                                placeholder="Ketik disini..."
                            ></vue-editor>
                            <small id="document_type" v-if="!v.description.required" class="form-text text-muted">Deskripsi Keputusan Wajid Diisi!</small>
                            <div class="form-check mb-3 mt-3">
                                <input class="form-check-input" type="checkbox" v-model="item.is_setting_margin"  id="flexCheckDefault">
                                <label class="form-check-label" for="flexCheckDefault">
                                    Setting Margin
                                </label>
                            </div>
                            <div class="row mt-2" v-if="item.is_setting_margin">
                                <div class="form-group col">
                                    <label for="date">Margin Atas</label>
                                    <input type="number" class="form-control"  v-model="item.margin_top" id="title" aria-describedby="title" placeholder="Margin Atas">
                                </div>
                                <div class="form-group col">
                                    <label for="date">Margin Bawah</label>
                                    <input type="number" class="form-control"  v-model="item.margin_bottom" id="title" aria-describedby="title" placeholder="Margin Bawah">
                                </div>
                                <div class="form-group col">
                                    <label for="date">Line Space</label>
                                    <input type="number" class="form-control"  v-model="item.margin_left" id="title" aria-describedby="title" placeholder="Line Space">
                                </div>
                                <!-- <div class="form-group col">
                                    <label for="date">Margin Kanan</label>
                                    <input type="number" class="form-control"  v-model="item.margin_right" id="title" aria-describedby="title" placeholder="Margin Kanan">
                                </div> -->
                            </div>

                            <button @click="addExtendForm(index, 'paragraf')" class="btn btn-sm btn-success mr-1 mb-3"><CIcon name="cilMedicalCross" /> Judul Tengah</button>
                            <button @click="addExtendForm(index, 'table')" class="btn btn-sm btn-success mr-1 mb-3"><CIcon name="cilMedicalCross" /> Tabel</button>
                            

                            <!-- paragraph center & table extendtion form -->
                            <table class="table table-borderless" v-if="item.extend_form !== ''">
                                <tr v-for="(extend, index_extend) in item.extend_form" :key="index_extend">
                                    <template >
                                        <td class="p-1">
                                        <div class="form-group" v-if="extend.type == 'paragraf'">
                                            <input type="text" class="form-control" v-model="extend.text"  placeholder="Ketik Judul Tengah">
                                        </div>
                                        <div v-else class="mb-2">
                                            <hr class="mt-0" />
                                            
                                            <div class="row mt-2 mb-2">
                                                <div class="col-3">
                                                    Setting Border
                                                </div>
                                                <div class="col-4">
                                                    <select v-model="extend.type_border">
                                                        <option value="1" >YA</option>
                                                        <option value="0">TIDAK</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="row mt-2 mb-2">
                                                <div class="col-3">
                                                    Setting Header Center
                                                </div>
                                                <div class="col-4">
                                                    <select v-model="extend.header_center">
                                                        <option value="1" >YA</option>
                                                        <option value="0">TIDAK</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="row mt-2 mb-2">
                                                <div class="col-3">
                                                    Align Table
                                                </div>
                                                <div class="col-4">
                                                    <select v-model="extend.align_table">
                                                        <option value="kanan">Rata Kanan</option>
                                                        <option value="kiri">Rata Kiri</option>
                                                        <option value="tengah">Rata Tengah</option>
                                                        <option value="penuh">Layar Penuh</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="table-responsive">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th style="text-align: center;" v-for="(header, index_header) in extend.headers" :key="index_header">
                                                                <button class="btn btn-sm btn-danger" @click="deleteColumn(index, index_extend,index_header)">Hapus Kolom {{ index_header+1 }}</button>
                                                            </th>
                                                            <th>
                                                                <button class="btn btn-sm btn-secondary" @click="addColumn(index, index_extend)">Tambah Kolom</button>
                                                            </th> 
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(row, rowIndex) in extend.tableData" :key="rowIndex">
                                                            <td v-for="(cell, colIndex) in row" :key="colIndex">
                                                                <input type="text" class="form-control mb-2"  v-model="cell.long" aria-describedby="title" placeholder="Panjang Kolom">
                                                                <vue-editor :editor-toolbar="customToolbar"
                                                                    v-model="cell.value"
                                                                    :placeholder="'Baris ' + (rowIndex+1) + ' Kolom ' + (colIndex+1)"
                                                                ></vue-editor>
                                                                <!-- <textarea v-model="cell.value" rows="3" class="form-control" :placeholder="'Baris ' + (rowIndex+1) + ' Kolom ' + (colIndex+1)"></textarea> -->
                                                            </td>
                                                            <td>
                                                                <button class="btn btn-sm btn-danger" @click="deleteRow(index, index_extend, rowIndex)">Hapus Baris</button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <button class="btn btn-sm btn-secondary ml-3" @click="addRow(index, index_extend)">Tambah Baris</button>
                                        </div>
                                    </td>
                                    <td class="p-1" style="width: 20px;vertical-align: baseline;">
                                        <button @click="deleteExtendForm(index, index_extend)" class="btn btn-sm btn-danger m-1">Hapus</button>
                                    </td>
                                    </template>
                                
                                </tr>
                            </table>

                            <hr class="mt-0" />
                        </td>
                        <td style="width: 20px;vertical-align: baseline;">
                            <button @click="deleteDetail(index)" class="btn btn-sm btn-danger m-1">Hapus</button>
                        </td>
                        <td style="width: 20px;vertical-align: baseline;">
                            <button @click="move(index,index-1)" :disabled="index==0" class="btn btn-sm btn-success m-1">Naik</button>
                        </td>
                        <td style="width: 20px;vertical-align: baseline;">
                            <button @click="move(index,index+1)" :disabled="index==(document_decisions.length-1)" class="btn btn-sm btn-warning m-1">Turun</button>
                        </td>
                    </tr>
                </table>
            </div>
            <button @click="add" class="btn btn-sm mt-3 btn-success"><CIcon name="cilMedicalCross" /> Tambah Keputusan</button>
        </template>

        
        <h4 class="mt-4">Tembusan </h4>
        <div v-for="(item, index) in document_copies" :key="index">
                        <input type="text" v-model="item.copy_text" class="form-control" placeholder="contoh : Sekretaris Daerah Kabupaten Bandung">
                        <button @click="deleteCopy(index)" class="btn btn-sm btn-danger m-1">Hapus</button>
                        
        </div>
        
        <button @click="addCopy" class="btn btn-sm btn-primary mt-3 ml-1" >Tambah Tembusan</button>
    </div>
</template>

<script>
    import {validationMixin} from 'vuelidate'
    import { required } from 'vuelidate/lib/validators'
    import { VueEditor } from "vue2-editor";
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    // import { ClassicEditor, Table, TableCellProperties, TableProperties, TableToolbar } from '@ckeditor/ckeditor5-table';

    Array.prototype.move = function(from, to) {
        this.splice(to, 0, this.splice(from, 1)[0]);
        return this;
    };

    export default {
        props: ['clickedNext', 'currentStep'],
        mixins: [validationMixin],
        data() {
            return {
                document: {},
                document_decisions: [
                    {
                        description: '',
                        margin_top: 0,
                        margin_left: 0,
                        margin_bottom: 0,
                        margin_right: 0,
                        extend_form: []
                    }
                ],
                // still bugg
                document_copies: [],
                document_type: "",
                customToolbar: [
                    ["bold", "italic", "underline"],
                    [{ list: "ordered" }, { list: "bullet" }],
                ],
                editor: ClassicEditor,
                editorData: '',
                editorConfig: {
                    // The configuration of the editor.
                    resize_maxWidth: 800,
                    // plugins: [ Table, TableToolbar, TableProperties, TableCellProperties, Bold, /* ... */ ],
                    // toolbar: [ 'insertTable', /* ... */ ],
                    // table: {
                    //     contentToolbar: [
                    //         'tableColumn', 'tableRow', 'mergeTableCells',
                    //         'tableProperties', 'tableCellProperties'
                    //     ],

                    //     tableProperties: {
                    //         // Configuration of the TableProperties plugin.
                    //         // ...
                    //     },

                    //     tableCellProperties: {
                    //         // Configuration of the TableCellProperties plugin.
                    //         // ...
                    //     }
                    // }
                }
            }
        },
        components: {
            VueEditor
        },
        validations: {
            document_decisions: {
                $each: {
                    description: { required },
                }
            }
        },
        watch: {
            $v: {
                handler: function (val) {
                    if(!val.$invalid && this.document_decisions.length > 0) {
                        this.$store.commit('document/setDocumentDecisions', this.document_decisions);
                        this.$store.commit('document/setDocumentCopies', this.document_copies);
                        this.$emit('can-continue', {value: true});
                    } else {
                        this.$emit('can-continue', {value: false});
                    }
                },
                deep: true
            },
            currentStep(val) {
                if(!this.$v.invalid) {
                    this.$emit('can-continue', {value: true});
                } else {
                    this.$emit('can-continue', {value: false});
                }
            }
        },
        methods: {
            addAyat(babIndex, indexExtend) {
                this.document_decisions[babIndex].extend_form[indexExtend].ayat.push({
                    value: '', 
                    subayat: [
                        {
                            value: ''
                        }
                    ] 
                })
            },
            removeAyat(babIndex, indexExtend, indexAyat) {
                this.document_decisions[babIndex].extend_form[indexExtend].ayat.splice(indexAyat, 1);
            },
            getNumberParagraf(babIndex, indexExtend) {
                // Filter hanya elemen dengan type === 'bagian' di BAB saat ini
                const bagianList = this.document_decisions[babIndex].extend_form.filter(f => f.type === 'paragraf');

                // Cari indeks elemen saat ini di daftar bagianList
                const bagianIndex = bagianList.findIndex((item, index) => {
                    // Cocokkan elemen extend_form dengan indexExtend
                    return this.document_decisions[babIndex].extend_form.indexOf(item) === indexExtend;
                });

                // Jika elemen ditemukan, kembalikan indeks + 1 (agar dimulai dari 1)
                return bagianIndex !== -1 ? bagianIndex + 1 : 0;
            },
            getNumberBagian(babIndex, indexExtend) {
                // Filter hanya elemen dengan type === 'bagian' di BAB saat ini
                const bagianList = this.document_decisions[babIndex].extend_form.filter(f => f.type === 'bagian');

                // Cari indeks elemen saat ini di daftar bagianList
                const bagianIndex = bagianList.findIndex((item, index) => {
                    // Cocokkan elemen extend_form dengan indexExtend
                    return this.document_decisions[babIndex].extend_form.indexOf(item) === indexExtend;
                });

                // Jika elemen ditemukan, kembalikan indeks + 1 (agar dimulai dari 1)
                return this.pembilang(bagianIndex !== -1 ? bagianIndex + 1 : 0, true);
            },
            pembilang(nilai, isPutusan){
                nilai = Math.abs(nilai);
                var simpanNilaiBagi=0;
                var huruf = ["", "satu", "dua", "tiga", "empat", "lima", "enam", "tujuh", "delapan", "sebilan", "sebuluh", "sebelas"];
                var temp="";
            
                if (nilai < 12) {
                    temp = ""+huruf[nilai];
                }
                else if (nilai <20) {
                    console.log(nilai - 10, "nilai")
                    temp = this.pembilang(nilai - 10) + " belas";
                }
                else if (nilai < 100) {
                    simpanNilaiBagi = Math.floor(nilai/10);
                    temp = this.pembilang(simpanNilaiBagi)+" puluh"+ this.pembilang(nilai % 10);
                }
                else if (nilai < 200) {
                    temp = " seratus" + this.pembilang(nilai - 100);
                }
                else if (nilai < 1000) {
                    simpanNilaiBagi = Math.floor(nilai/100);
                    temp = this.pembilang(simpanNilaiBagi) + " ratus" + this.pembilang(nilai % 100);
                }
                else if (nilai < 2000) {
                    temp = " seribu" + this.pembilang(nilai - 1000);
                }
                else if (nilai < 1000000) {
                    simpanNilaiBagi = Math.floor(nilai/1000);
                    temp = this.pembilang(simpanNilaiBagi) + " ribu" + this.pembilang(nilai % 1000);
                } 
                else if (nilai < 1000000000) {
                    simpanNilaiBagi = Math.floor(nilai/1000000);
                    temp =this.pembilang(simpanNilaiBagi) + " juta" + this.pembilang(nilai % 1000000);
                } 
                else if (nilai < 1000000000000) {
                    simpanNilaiBagi = Math.floor(nilai/1000000000);
                    temp = this.pembilang(simpanNilaiBagi) + " miliar" + this.pembilang(nilai % 1000000000);
                } 
                else if (nilai < 1000000000000000) {
                    simpanNilaiBagi = Math.floor(nilai/1000000000000);
                    temp = this.pembilang(nilai/1000000000000) + " triliun" + this.pembilang(nilai % 1000000000000);
                }
            
                if (isPutusan) {
                    return 'Ke'+temp;
                } else {
                    return temp;
                }
                
            },
            getNumberPasal(babIndex, pasalIndex) {
                let totalPasal = 0;

                // Loop hingga BAB saat ini
                for (let i = 0; i < babIndex; i++) {
                    // Tambahkan jumlah elemen dengan type === 'paragraf' di setiap BAB sebelumnya
                    totalPasal += this.document_decisions[i].extend_form.filter(item => (item.type === 'pasal' || item.type === 'pasal-ayat')).length;
                }

                // Hitung hanya elemen bertipe 'paragraf' di BAB saat ini
                const currentPasalList = this.document_decisions[babIndex].extend_form.filter(item =>(item.type === 'pasal' || item.type === 'pasal-ayat'));

                // Cari indeks elemen pasal di daftar elemen bertipe 'paragraf'
                const filteredPasalIndex = currentPasalList.findIndex((item) => {
                    return this.document_decisions[babIndex].extend_form.indexOf(item) === pasalIndex;
                });

                // Tambahkan indeks pasal saat ini (jika ditemukan)
                if (filteredPasalIndex !== -1) {
                    totalPasal += filteredPasalIndex + 1; // Indeks dimulai dari 0, jadi tambahkan 1
                }

                return totalPasal; // Kembalikan nomor pasal
            },
            addColumn(index, index_extend) {
                const newColumnHeader = `Column ${this.document_decisions[index].extend_form[index_extend].headers.length + 1}`;
                this.document_decisions[index].extend_form[index_extend].headers.push(newColumnHeader);

                this.document_decisions[index].extend_form[index_extend].tableData.forEach(row => {
                    row.push({ value: '' });
                });
            },
            deleteColumn(index, index_extend, rowIndex) {
                this.document_decisions[index].extend_form[index_extend].headers.splice(rowIndex, 1);
                this.document_decisions[index].extend_form[index_extend].tableData.forEach((row) => {
                    row.splice(rowIndex, 1);
                });
            },
            addRow(index, index_extend) {
                const newRow = [];
                for (let i = 0; i < this.document_decisions[index].extend_form[index_extend].headers.length; i++) {
                    newRow.push({ value: '' });
                }
                this.document_decisions[index].extend_form[index_extend].tableData.push(newRow);
            },
            deleteRow(index, index_extend, rowIndex) {
                this.document_decisions[index].extend_form[index_extend].tableData.splice(rowIndex, 1);
            },
            deleteExtendForm(index, index_extend) {
                this.document_decisions[index].extend_form.splice(index_extend, 1);
            },
            addExtendForm(index, type) {
                
                if (this.document_decisions[index].extend_form == "") {
                    this.document_decisions[index].extend_form = [];
                }

                this.document_decisions[index].extend_form.push({
                    text: '',
                    type: type,
                    ayat: [
                        { 
                            value: '', 
                            subayat: [
                                {
                                    value: ''
                                }
                            ] 
                        }
                    ],
                    headers: ['Kolom 1', 'Kolom 2', 'Kolom 3'],
                    tableData: [
                        [
                            { value: '' },
                            { value: '' },
                            { value: '' }
                        ],
                        [
                            { value: '' },
                            { value: '' },
                            { value: '' }
                        ],
                    ]
                })
            },
            add() {
                this.document_decisions.push({
                    description: '',
                    margin_top: 0,
                    margin_left: 0,
                    margin_bottom: 0,
                    margin_right: 0,
                    extend_form: []
                })
            },
            deleteDetail(index) {
                this.document_decisions.splice(index, 1);
            },
            move(from, to) {
                this.document_decisions.move(from, to);
            },
            addCopy() {
                this.document_copies.push({
                    copy_text: '',
                    
                })
            },
            deleteCopy(index) {
                this.document_copies.splice(index, 1);
            }

        },
        mounted() {
            // test push
            let _document_decisions = this.$store.state.document.document_decisions;
            this.document_type = this.$store.state.document.document_type;
            this.document = this.$store.state.document.document;
            this.document.title = this.document.title.toString().replace(/<\/?p>/g, '');
 
            _document_decisions.forEach(element => {
                if (typeof element.extend_form == "string") {
                    element.extend_form = [];
                }
            });

            if (_document_decisions.length > 0) {
                this.document_decisions = _document_decisions;
            }

            let _document_copies = this.$store.state.document.document_copies;
            if (_document_copies.length > 0) {    
                this.document_copies = _document_copies;
            }

            
            if(!this.$v.$invalid) {
                this.$emit('can-continue', {value: true});
            } else {
                this.$emit('can-continue', {value: false});
            }
        }
    }
</script>

<style>
    /* .table {
        white-space: normal !important;
    } */
    .ck-editor .ck-editor__main .ck-content {
        min-height: 200px !important;
        max-width: 900px !important;
        width: 900px !important;
    }
</style>