<template>
  <!-- HTML template for the component -->
  <div>
    <div class="row mt-3">
      <div class="col-4">&nbsp;</div>
      <div class="col-8">
        <p style="margin-left: 80px">Ditetapkan di Soreang</p>
        <p  v-if="document.document.penomoran" style="margin-left:80px">Pada Tanggal {{ formatDate(document.document.determination_date) }}</p>
        <p  v-else style="margin-left:80px">Pada Tanggal </p>
        <p style="padding: 20px 0px 15px 10px; text-align: center">
          SEKRETARIS DAERAH KABUPATEN BANDUNG,
        </p>
        <div style="text-align: center; 
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 27%;"><qr-code  size="100" :text="document.document.qrcode"></qr-code></div>
        <p
          style="padding: 0px 20px 0px 10px; line-height: 3; text-align: center"
        >
        CAKRA AMIYANA 
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // Component's data properties
  data() { 
    return {
      document:{}
    };
  },
  // Component's methods
  methods: {
    formatDate(date) {
        return this.$moment(date).format("Do MMMM YYYY");
    },
  },
  mounted() {
      // this.$emit('can-continue', {value: true});
      this.document = this.$store.state.document;
  },
};
</script>
